import { useEffect, useState } from "react";
import AnswersList from "../../components/Answers/AnswersList";
import SearchNeta from "../../components/Home/SearchNeta";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { answerListAction } from "../../store/API/QuestionAnswerApis";
import Pagination from "../../components/common/Pagination";
import { useNavigate, useSearchParams } from "react-router-dom";

const Answers = () => {
  const { answerList } = useAppSelector((state) => state.quesAns);
  const [remountComponent, setRemountComponent] = useState(0);
  const navigate = useNavigate();
  const limit = 4;

  const [searchParams] = useSearchParams();
  
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (searchParams.get("seat")) {
      dispatch(
        answerListAction({
          page: 1,
          page_size: limit,
          seat: searchParams.get("seat"),
        })
      );
    } else if (searchParams.get("upazila")) {
      dispatch(
        answerListAction({
          page: 1,
          page_size: limit,
          upazila: searchParams.get("upazila"),
        })
      );
    } else if (searchParams.get("district")) {
      dispatch(
        answerListAction({
          page: 1,
          page_size: limit,
          district: searchParams.get("district"),
        })
      );
    } else if (searchParams.get("division")) {
      dispatch(
        answerListAction({
          page: 1,
          page_size: limit,
          division: searchParams.get("division"),
        })
      );
    } else {
      dispatch(
        answerListAction({
          page: 1,
          page_size: limit,
        })
      );
    }
  }, [searchParams, dispatch]);
  const handlePageClick = (data: any) => {
    if (searchParams.get("seat")) {
      dispatch(
        answerListAction({
          page: data.selected + 1,
          page_size: limit,
          seat: searchParams.get("seat"),
        })
      );
    } else if (searchParams.get("upazila")) {
      dispatch(
        answerListAction({
          page: data.selected + 1,
          page_size: limit,
          upazila: searchParams.get("upazila"),
        })
      );
    } else if (searchParams.get("district")) {
      dispatch(
        answerListAction({
          page: data.selected + 1,
          page_size: limit,
          district: searchParams.get("district"),
        })
      );
    } else if (searchParams.get("division")) {
      dispatch(
        answerListAction({
          page: data.selected + 1,
          page_size: limit,
          division: searchParams.get("division"),
        })
      );
    } else {
      dispatch(
        answerListAction({
          page: data.selected + 1,
          page_size: limit,
        })
      );
    }
    window.scrollTo({
      top: 180,
      behavior: "smooth",
    });
  };
  const handleSearchSubmit = (data: any) => {
    if (data?.seat) {
      navigate(`/answers/list?seat=${data?.seat?.value}`);
    } else if (data?.upazila) {
      navigate(`/answers/list?upazila=${data?.upazila?.value}`);
    } else if (data?.district) {
      navigate(`/answers/list?district=${data?.district?.value}`);
    } else if (data?.division) {
      navigate(`/answers/list?division=${data?.division?.value}`);
    }
    setRemountComponent(Math.random());
  };
  return (
    <>
      <section className="three_section bg-[#fafbff] lg:py-10 py-8">
        <div className="container-fluid mx-auto">
          <h3 className="text-green-500 xl:text-2xl md:text-xl text-lg font-medium">
            latest
            <span className="text-red-500 inline-block relative z-[1] p-2 after:absolute after:block after:left-0 after:top-0 after:bg-white after:border after:shadow-theme_shadow after:w-full after:h-full after:skew-x-[20deg] after:-z-10">
              Answer
            </span>
            Update
            <span>(Total: {answerList?.count})</span>
          </h3>
          <SearchNeta handleSearchSubmit={handleSearchSubmit} />
          <AnswersList />
          <div className="mt-8 flex justify-end" key={remountComponent}>
            <Pagination
              pageCount={answerList?.count && Math.ceil(answerList?.count / limit)}
              handlePageClick={handlePageClick}
              pageRange={5}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Answers;
