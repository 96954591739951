import React from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { QuestionListType } from '../../models';
import { Link } from 'react-router-dom';
import Pagination from '../common/Pagination';
import { userQuestionListAction } from '../../store/API/QuestionAnswerApis';

const ProfileQuestionList = () => {
    const { userQuestionList } = useAppSelector(state => state.quesAns)
    const limit = 4;
    const dispatch = useAppDispatch()
    const handlePageClick = (data: any) => {
        dispatch(
          userQuestionListAction({
            page: data.selected + 1,
            page_size: limit,
          })
        );
      window.scrollTo({
        top: 180,
        behavior: "smooth",
      });
    };
    
  return (
    <div x-show="openTab === 2">
      <h4 className="text-green-500 lg:text-3xl md:text-2xl text-xl font-medium">
        All Questions Asked by Shahrukh Sayel
      </h4>
      <table className="w-full text-sm text-left text-gray-500 border-collapse">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3">
              Subject
            </th>
            <th scope="col" className="px-6 py-3">
              Sender
            </th>
            <th scope="col" className="px-6 py-3">
              NeTa
            </th>
            <th scope="col" className="px-6 py-3">
              Last Update
            </th>
            <th scope="col" className="px-6 py-3">
              Replies
            </th>
          </tr>
        </thead>
        <tbody>
          {userQuestionList?.results?.map((item: QuestionListType) => (
            <tr className="bg-white border-b" key={item?.id}>
              <td className="px-6 py-4 max-w-[200px] overflow-hidden overflow-ellipsis ">
                <Link
                  to={`/neta/details/${item?.member?.id}?q=${item?.id}`}
                  state={{ paramsData: 3, paramsType: "Question" }}
                  className="text-green-500 hover:underline"
                >
                  {item?.subject}
                </Link>
              </td>
              <td className="px-6 py-4 whitespace-nowrap max-w-[200px] overflow-hidden overflow-ellipsis">
                {item?.sender?.first_name} {item?.sender?.last_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap max-w-[200px] overflow-hidden overflow-ellipsis">
                {item?.member?.name}
              </td>
              <td className="px-6 py-4 max-w-[200px] overflow-hidden overflow-ellipsis">
                {item?.published_date}
              </td>
              <td className="px-6 py-4 max-w-[200px] overflow-hidden overflow-ellipsis">
                {item?.ans_count}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-8 flex justify-end">
        <Pagination
          pageCount={
            userQuestionList?.count &&
            Math.ceil(userQuestionList?.count / limit)
          }
          handlePageClick={handlePageClick}
          pageRange={5}
        />
      </div>
      <div className="qa_content py-10">
        <p className="text-red-500 text-base font-normal">
          No Questions Available
        </p>
      </div>
    </div>
  );
}

export default ProfileQuestionList