import React from 'react'
import NewsDetailsContent from './NewsDetailsContent'
import NewsDetailsRelatedPost from './NewsDetailsRelatedPost';

const NewsContent = () => {
  return (
    <div className="lg:col-span-8 col-span-12">
      <NewsDetailsContent />
      <NewsDetailsRelatedPost/>
    </div>
  );
}

export default NewsContent