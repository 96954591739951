import React, { useEffect } from "react";
import UpazilaList from "../../../components/Admin/Upazila/UpazilaList";
import { useAppDispatch } from "../../../store/hooks";
import { getUpazilaList } from "../../../store/apis";

const Upazila = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getUpazilaList());
  }, [dispatch]);
  return (
    <div className="flex flex-col gap-4 px-4 pt-6 text-start justify-center">
      <UpazilaList />
    </div>
  );
};

export default Upazila;
