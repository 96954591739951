import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { registration } from "../../store/API/UserApis";

const Registration = () => {
  const { error } = useAppSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = handleSubmit((data) => {
    dispatch(
      registration({
        first_name: data.first_name,
        last_name: data.last_name,
        phone_number: data.phone_number,
        email: data.email,
        password: data.password,
        router: navigate,
      })
    );
  });

  return (
    <section className="login_area bg-[#fafbff] lg:py-20 sm:py-10 py-8">
      <div className="container-fluid mx-auto">
        <div className="max-w-[585px] w-full mx-auto p-7 bg-white rounded-[10px] outline outline-1 outline-gray-300">
          <div className="mb-9">
            <h4 className="flex items-center justify-center text-gray-900 mb-1">
              Welcome to
              <span className="bg-gradient-to-r from-green-500 to-red-400 bg-clip-text fill-transparent ml-1">
                NeTa
              </span>
            </h4>
          </div>
          <form onSubmit={onSubmit}>
            <div className="flex flex-wrap flex-row">
              <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/2 bg-white group rounded-md">
                <input
                  {...register("first_name", { required: true })}
                  id="firstName"
                  type="text"
                  name="first_name"
                  className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="firstName"
                  className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                >
                  First Name <span className="text-red-500">&nbsp; *</span>
                </label>
                {errors?.first_name && (
                  <p className="text-red-500 text-start">
                    First name is required.
                  </p>
                )}
              </div>

              <div className="mb-4 relative w-full lg:w-1/2 bg-white group rounded-md">
                <input
                  {...register("last_name", { required: true })}
                  id="lastName"
                  type="text"
                  name="last_name"
                  className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="lastName"
                  className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                >
                  Last Name <span className="text-red-500">&nbsp; *</span>
                </label>
                {errors?.last_name && (
                  <p className="text-red-500 text-start">
                    Last name is required.
                  </p>
                )}
              </div>
            </div>
            <div className="mb-4 relative w-full bg-white group rounded-md">
              <input
                {...register("email", { required: true })}
                id="email"
                type="email"
                name="email"
                className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                placeholder=" "
              />
              <label
                htmlFor="email"
                className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
              >
                Email <span className="text-red-500">&nbsp; *</span>
              </label>
              {errors?.email && (
                <p className="text-red-500 text-start">Email is required.</p>
              )}
            </div>
            <div className="mb-4 relative w-full bg-white group rounded-md">
              <input
                {...register("phone_number", { required: true })}
                id="phone"
                type="text"
                name="phone_number"
                className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                placeholder=" "
              />
              <label
                htmlFor="phone"
                className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
              >
                Phone Number <span className="text-red-500">&nbsp; *</span>
              </label>
              {errors?.phone_number && (
                <p className="text-red-500 text-start">
                  Phone Number is required.
                </p>
              )}
            </div>
            <div className="mb-4 relative w-full bg-white group rounded-md">
              <input
                {...register("password", { required: true })}
                id="password"
                type="password"
                className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                placeholder=" "
              />
              <label
                htmlFor="password"
                className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
              >
                Password <span className="text-red-500">&nbsp; *</span>
              </label>
              <p className="ml-2 mt-1 text-xs text-start text-gray-500">
                Password must contains 1 uppercase, 1 lowercase, 1 digit and 8
                characters.
              </p>

              {errors?.password && (
                <p className="text-red-500 text-start">Password is required.</p>
              )}
            </div>
            <div className="mb-4 relative w-full bg-white group rounded-md">
              <input
                {...register("confirmPassword", {
                  validate: (value) => {
                    return (
                      value === password.current || "The passwords do not match"
                    );
                  },
                })}
                id="confirm-password"
                type="password"
                name="confirmPassword"
                className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                placeholder=" "
              />
              <label
                htmlFor="confirm-password"
                className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
              >
                Confirm Password <span className="text-red-500">&nbsp; *</span>
              </label>
              {errors?.confirmPassword && (
                <p className="text-red-500 text-start">Password don't match</p>
              )}
            </div>
            {Array.isArray(error) && (
              <div className="mt-4 space-y-3">
                {error.map((err, index) => (
                  <div
                    key={index}
                    className="p-3 bg-red-100 text-red-700 border border-red-300 rounded-md items-center gap-3"
                  >
                    <p className="flex-grow font-medium whitespace-pre-line">
                      {err}
                    </p>
                  </div>
                ))}
              </div>
            )}

            <div className="flex items-center justify-between">
              <Link to={"/"} className="font-normal text-danger-500">
                Forget password?
              </Link>
            </div>
            <button
              type="submit"
              className="w-full px-8 py-4 mt-9 rounded-md font-medium text-base flex items-center justify-center bg-green-500 text-white hover:bg-green-600 transition-colors duration-200 mr-4"
            >
              Register
            </button>
            <div className="py-6 text-center">
              <p className="font-normal text-gray-700">OR</p>
            </div>
            <p className="font-normal text-gray-700 text-center">
              I have an account? {" "}
              <Link to="/login" className="text-green-500">
                Login Now!
              </Link>
            </p>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Registration;
