import React from 'react'
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { QuestionListType } from '../../../models';

const NetaProfileQuestion = () => {
  const {memberQuestion} = useAppSelector(state => state.quesAns)
  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left text-gray-500 border-separate">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-2 py-1">
              Subject
            </th>
            <th scope="col" className="px-2 py-1">
              Sender
            </th>
            <th scope="col" className="px-2 py-1">
              Last Update
            </th>
            <th scope="col" className="px-2 py-1">
              Replies
            </th>
          </tr>
        </thead>
        <tbody>
          {memberQuestion
            ?.slice()
            ?.reverse()
            ?.map((item: QuestionListType) => (
              <tr className="bg-white border-b" key={item?.id}>
                <th
                  scope="row"
                  className="px-2 py-2 font-medium whitespace-nowrap text-base text-green-500"
                >
                  <Link
                    to={`/neta/details/${item?.member?.id}?q=${item?.id}`}
                    state={{ paramsData: 3, paramsType: "Question" }}
                  >
                    {item?.subject}
                  </Link>
                </th>
                <td className="px-2 py-2 whitespace-nowrap text-base text-green-500">
                  {item?.sender?.first_name} {item?.sender?.last_name}
                </td>
                <td className="px-2 py-2">{item?.published_date}</td>
                <td className="px-2 py-2">{item?.ans_count}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default NetaProfileQuestion