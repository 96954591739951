import React from 'react'
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { AnswerListType } from '../../models';
import parse from 'html-react-parser'

const AnswersList = () => {
  const { answerList } = useAppSelector((state) => state.quesAns);
  return (
    <div className="relative overflow-x-auto pt-10 text-start">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Content
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Subject
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Member
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Answered By
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Published Date
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {answerList?.results?.map((item: AnswerListType) => (
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <Link
                  to="/neta/details/5"
                  className="text-green-500 hover:underline"
                  state={{ paramsData: 3, paramsType: "Answer" }}
                >
                  {item?.content && (
                    <div className="max-w-[200px] overflow-hidden overflow-ellipsis">
                      {parse(item.content)}
                    </div>
                  )}
                </Link>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-[200px] overflow-hidden overflow-ellipsis">
                <a href="answer-view.html">{item?.question?.subject}</a>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-[200px] overflow-hidden overflow-ellipsis">
                {item?.member?.name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-[200px] overflow-hidden overflow-ellipsis">
                {item?.answer_by?.first_name} {item?.answer_by?.last_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-[200px] overflow-hidden overflow-ellipsis">
                {item?.published_date}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* <table className="w-full text-sm text-left text-gray-500 border-separate">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3">
              Answer
            </th>
            <th scope="col" className="px-6 py-3">
              Question
            </th>
            <th scope="col" className="px-6 py-3">
              NeTa
            </th>
            <th scope="col" className="px-6 py-3">
              Asked by
            </th>
            <th scope="col" className="px-6 py-3">
              Time
            </th>
          </tr>
        </thead>
        <tbody>
          {answerList?.results?.map((item: AnswerListType) => (
            <tr className="bg-white border-b">
              <th
                scope="row"
                className="px-6 py-4 font-medium max-w-[200px] text-base text-green-500"
              >
                <Link
                  to="/neta/details/5"
                  className="mr-5 max-h-12 overflow-hidden text-ellipsis inline-block"
                  state={{ paramsData: 3, paramsType: "Answer" }}
                >
                  {item?.content && parse(item.content)}
                </Link>
                ...
              </th>
              <td className="px-6 py-4 max-w-xs sm:max-w-[200px] whitespace-nowrap overflow-ellipsis text-base text-green-500 ml-3">
                <a href="answer-view.html">{item?.question?.subject}</a>
              </td>
              <td className="px-6 py-4 max-w-xs sm:max-w-[200px] whitespace-nowrap overflow-ellipsis text-base text-green-500">
                {item?.member?.name}
              </td>
              <td className="px-6 py-4 max-w-xs sm:max-w-[200px] whitespace-nowrap overflow-ellipsis text-base text-green-500">
                {item?.answer_by?.first_name} {item?.answer_by?.last_name}
              </td>
              <td className="px-6 py-4">{item?.published_date}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  );
}

export default AnswersList