import { Link } from "react-router-dom";
import { TopNewsType } from "../../../models";
import { useAppSelector } from "../../../store/hooks";

const LatestNews = () => {
  const { generalNews } = useAppSelector((state) => state.common);
  return (
    <section className="bg-[#fafbff] text-gray-600 lg:py-10 py-8">
      <div className="md:container container-fluid mx-auto">
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-4">
          {generalNews?.map((item: TopNewsType) => (
            <div
              key={item?.id}
              className="bg-white border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
            >
              <Link to={`/news/details/${item.id}`}>
                <img
                  className="lg:h-48 md:h-36 w-full object-cover object-center"
                  src={item?.image}
                  alt="blog"
                />
              </Link>
              <div className="p-6 text-start">
                <div className="flex items-center gap-3">
                  <Link
                    to={`/news/details/${item.id}`}
                    className="text-green-500 md:text-base text-sm font-normal"
                  >
                    {item?.uploader_name?.first_name}{" "}
                    {item?.uploader_name?.last_name}
                  </Link>
                  <span className="post_date text-[#a4a4a4] text-sm font-normal">
                    {item?.published_date}
                  </span>
                </div>
                <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                  {item?.title}
                </h1>
                <div className="flex items-center flex-wrap">
                  <Link
                    to={`/news/details/${item.id}`}
                    className="text-green-500 inline-flex items-center lg:mb-0"
                  >
                    Learn More
                    <svg
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </Link>
                  <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                    <svg
                      className="w-4 h-4 mr-1"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                      <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                    {item?.view}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LatestNews;
