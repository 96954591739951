import { Link } from "react-router-dom";
import { TopNewsType } from "../../models";
import { useAppSelector } from "../../store/hooks";

const NewsDetailsRelatedPost = () => {
  const {newsSuggestion} = useAppSelector(state => state.common)
  return (
    <div className="related_post pt-8">
      <h4 className="text-black md:text-lg text-base font-medium pb-3">
        এ সম্পর্কিত
      </h4>
      <div className="flex flex-col gap-5 text-start">
        {newsSuggestion?.length > 0 ? newsSuggestion?.map((item: TopNewsType) => (
          <div className="post_item flex sm:flex-row flex-col gap-3" key={item?.id}>
            <Link to={`/news/details/${item?.id}`}>
              <div className="post_thumb md:w-[250px] sm:w-[150px] w-full">
                <img className="w-full rounded-md" src={item?.image} alt="" />
              </div>
            </Link>
            <div className="post_content md:w-[calc(100%_-_262px)] sm:w-[calc(100%_-_162px)] w-full">
              <Link to={`/news/details/${item?.id}`}>
                <h4 className="text-black md:text-base text-sm font-semibold pb-2">
                  {item?.title}
                </h4>
              </Link>
            </div>
          </div>
        )) : <div className="text-center">No News Found</div>}
      </div>
    </div>
  );
}

export default NewsDetailsRelatedPost