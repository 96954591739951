
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { QuestionListType } from '../../models';

const QuestionsList = () => {
  const { questionList } = useAppSelector((state) => state.quesAns);
  return (
    <div className="relative overflow-x-auto pt-10">
      <table className="w-full text-sm text-left text-gray-500 border-collapse">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3">
              Subject
            </th>
            <th scope="col" className="px-6 py-3">
              Sender
            </th>
            <th scope="col" className="px-6 py-3">
              NeTa
            </th>
            <th scope="col" className="px-6 py-3">
              Last Update
            </th>
            <th scope="col" className="px-6 py-3">
              Replies
            </th>
          </tr>
        </thead>
        <tbody>
          {questionList?.results?.map((item: QuestionListType) => (
            <tr className="bg-white border-b" key={item?.id}>
              <td className="px-6 py-4 max-w-[200px] overflow-hidden overflow-ellipsis ">
                <Link
                  to={`/neta/details/${item?.member?.id}?q=${item?.id}`}
                  state={{ paramsData: 3, paramsType: "Question" }}
                  className="text-green-500 hover:underline"
                >
                  {item?.subject}
                </Link>
              </td>
              <td className="px-6 py-4 whitespace-nowrap max-w-[200px] overflow-hidden overflow-ellipsis">
                {item?.sender?.first_name} {item?.sender?.last_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap max-w-[200px] overflow-hidden overflow-ellipsis">
                {item?.member?.name}
              </td>
              <td className="px-6 py-4 max-w-[200px] overflow-hidden overflow-ellipsis">
                {item?.published_date}
              </td>
              <td className="px-6 py-4 max-w-[200px] overflow-hidden overflow-ellipsis">
                {item?.ans_count}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* <table className="w-full text-sm text-left text-gray-500 border-separate">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3">
              Subject
            </th>
            <th scope="col" className="px-6 py-3">
              Sender
            </th>
            <th scope="col" className="px-6 py-3">
              NeTa
            </th>
            <th scope="col" className="px-6 py-3">
              Last Update
            </th>
            <th scope="col" className="px-6 py-3">
              Replies
            </th>
          </tr>
        </thead>
        <tbody>
          {questionList?.results?.map((item: QuestionListType) => (
            <tr className="bg-white border-b" key={item?.id}>
              <th
                scope="row"
                className="px-6 py-4 font-medium whitespace-nowrap text-base text-green-500"
              >
                <Link
                  to="/neta/details/5"
                  state={{ paramsData: 3, paramsType: "Question" }}
                >
                  {item?.subject}
                </Link>
              </th>
              <td className="px-6 py-4 whitespace-nowrap text-base text-green-500">
                {item?.sender?.first_name} {item?.sender?.last_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-base text-green-500">
                {item?.member?.name}
              </td>
              <td className="px-6 py-4">{item?.published_date}</td>
              <td className="px-6 py-4">{item?.ans_count}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  );
}

export default QuestionsList