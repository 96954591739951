import { createSlice } from "@reduxjs/toolkit";
import { AnswerResultsType, AnswerType, QuestionAnsDetailsType,  QuestionListType,  QuestionResultsType, QuestionType } from "../models";
import {
  questionListAction,
  answerListAction,
  memberQuestion,
  memberQuestionDetails,
  recentAnswer,
  topQuestion,
  userQuestionListAction,
} from "./API/QuestionAnswerApis";


const initialState: {
  loading: boolean;
  topQuestion: QuestionType[];
  questionList: QuestionResultsType | null;
  userQuestionList: QuestionResultsType | null;
  answerList: AnswerResultsType | null;
  recentAnswer: AnswerType[];
  memberQuestion: QuestionListType[];
  questionAnsDetails: QuestionAnsDetailsType | null;
} = {
  loading: false,
  topQuestion: [],
  questionList: null,
  userQuestionList: null,
  answerList: null,
  recentAnswer: [],
  memberQuestion: [],
  questionAnsDetails: null,
};

const questionAnsSlice = createSlice({
  name: "questionAns",
  initialState: initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(topQuestion.fulfilled, (state, action) => {
      state.loading = false;
      state.topQuestion = action.payload;
    });
    builder.addCase(recentAnswer.fulfilled, (state, action) => {
      state.loading = false;
      state.recentAnswer = action.payload;
    });
    builder.addCase(memberQuestion.fulfilled, (state, action) => {
      state.loading = false;
      state.memberQuestion = action.payload;
    });
    builder.addCase(memberQuestionDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.questionAnsDetails = action.payload;
    });
    builder.addCase(questionListAction.fulfilled, (state, action) => {
      state.loading = false;
      state.questionList = action.payload;
    });
    builder.addCase(userQuestionListAction.fulfilled, (state, action) => {
      state.loading = false;
      state.userQuestionList = action.payload;
    });
    builder.addCase(answerListAction.fulfilled, (state, action) => {
      state.loading = false;
      state.answerList = action.payload;
    });
  },
  reducers: {},
});

export const questionAnsActions = questionAnsSlice.actions;
export default questionAnsSlice.reducer;