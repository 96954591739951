import { toast } from "react-toastify";
import { updateUserProfile } from "../../../store/API/UserApis";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import ModalContainer from "../../common/ModalContainer";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { getPartyList } from "../../../store/API/CommonApis";
import { PartyType } from "../../../models";
import Select from "react-select";
import { customStyles } from "../../Admin/District/DistrictCreate";
import { useParams } from "react-router-dom";

type PropsTypes = {
  openAmbassadorModal: boolean;
  closeAmbassadorModel: () => void;
};

const ApplyAmbassadorModal: React.FC<PropsTypes> = ({
  closeAmbassadorModel,
  openAmbassadorModal,
}) => {
  const { partyList } = useAppSelector((state) => state.common);
  const [partyOption, setPartyOption] = useState<
    { value: number; label: string }[]
    >([]);
   let { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const { userDetails } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const onSubmit = handleSubmit((data) => {
    try {
      dispatch(
        updateUserProfile({
          dob: data.dob,
          address: data.address,
          religion: data.religion,
          nationality: data.nationality,
          political_party: data.political_party?.label,
          alt_phone: data.alt_phone,
          facebook_link: data.facebook_link,
          twitter_link: data.twitter_link,
          linkedIn_link: data.linkedIn_link,
          voter_id: data.voter_id,
          requested: "AMBASSADOR",
          request_member: id,
        })
      );
      toast.success("Ambassador Application Successfully Submitted");
    } catch (error) {
      console.log(error);
    }
  });
  useEffect(() => {
    if (userDetails?.political_party && partyOption.length > 0) {
      let a: any = partyOption.find(
        (item) => item.label === userDetails?.political_party
      );
      
      setValue("political_party", { value: a.value, label: a.label });
    }
    setValue("dob", userDetails?.dob);
    setValue("address", userDetails?.address);
    setValue("religion", userDetails?.religion);
    setValue("nationality", userDetails?.nationality);
    setValue("alt_phone", userDetails?.alt_phone);
    setValue("facebook_link", userDetails?.facebook_link);
    setValue("twitter_link", userDetails?.twitter_link);
    setValue("linkedIn_link", userDetails?.linkedIn_link);
    setValue("voter_id", userDetails?.voter_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails, partyOption]);
  
  useEffect(() => {
    dispatch(getPartyList());
  }, [dispatch]);

  useEffect(() => {
    let option: { value: number; label: string }[] = [];
    if (partyList?.length > 0) {
      partyList.map((item: PartyType) =>
        option.push({ value: item.id, label: item.name })
      );
      setPartyOption(option);
    }
  }, [partyList, setValue]);
  return (
    <ModalContainer
      open={openAmbassadorModal}
      closeModal={closeAmbassadorModel}
    >
      <div className="pr_overlay fixed left-0 top-0 h-full w-full overflow-x-hidden overflow-y-auto bg-[rgba(0,0,0,0.5)] z-20">
        <div className="modal_dialog relative w-auto transform-none xl:max-w-[1000px] lg:max-w-[950px] md:max-w-[700px] sm:max-w-[500px] xs:max-w-[400px] xxs:max-w-[340px] sm:min-h-[calc(100%_-_3.5rem)] min-h-[calc(100%_-_1rem)] flex items-center my-8 mx-auto">
          <div className="bg-white relative flex flex-col w-full pointer-events-auto bg-clip-padding rounded-lg p-6">
            <span
              className="inline-flex justify-center items-center h-8 w-8 rounded-full bg-black bg-opacity-80 absolute -top-1 -right-1 z-50 cursor-pointer"
              id="em_close"
              onClick={closeAmbassadorModel}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
            <div className="e_modal_body">
              <h1 className="font-bold mb-3 text-center">
                Apply For Ambassador
              </h1>
              <form onSubmit={onSubmit}>
                <div className="flex flex-wrap flex-row">
                  <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/2 bg-white group rounded-md">
                    <input
                      {...register("address", { required: true })}
                      id="address"
                      type="text"
                      name="address"
                      className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="address"
                      className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                    >
                      Address <span className="text-red-500">&nbsp; *</span>
                    </label>
                    {errors?.address && (
                      <p className="text-red-500 text-start">
                        Address is required.
                      </p>
                    )}
                  </div>

                  <div className="mb-4 relative w-full lg:w-1/2 bg-white group rounded-md">
                    <input
                      {...register("dob", { required: true })}
                      id="dob"
                      type="text"
                      name="dob"
                      className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="dob"
                      className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                    >
                      Date of Birth{" "}
                      <span className="text-red-500">&nbsp; *</span>
                    </label>
                    {errors?.last_name && (
                      <p className="text-red-500 text-start">
                        Date of Birth is required.
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap flex-row">
                  <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/2 bg-white group rounded-md">
                    <input
                      {...register("religion", { required: true })}
                      id="religion"
                      type="text"
                      name="religion"
                      className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="religion"
                      className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                    >
                      Religion <span className="text-red-500">&nbsp; *</span>
                    </label>
                    {errors?.email && (
                      <p className="text-red-500 text-start">
                        Religion is required.
                      </p>
                    )}
                  </div>
                  <div className="mb-4 relative w-full lg:w-1/2 bg-white group rounded-md">
                    <input
                      {...register("nationality", { required: true })}
                      id="nationality"
                      type="text"
                      name="nationality"
                      className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="nationality"
                      className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                    >
                      Nationality <span className="text-red-500">&nbsp; *</span>
                    </label>
                    {errors?.phone_number && (
                      <p className="text-red-500 text-start">
                        Nationality is required.
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap flex-row">
                  {/* <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/2 bg-white group rounded-md">
                    <input
                      {...register("political_party", { required: true })}
                      id="political_party"
                      type="text"
                      className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="political_party"
                      className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                    >
                      Political Party{" "}
                      <span className="text-red-500">&nbsp; *</span>
                    </label>

                    {errors?.political_party && (
                      <p className="text-red-500 text-start">
                        Political Party is required.
                      </p>
                    )}
                  </div> */}
                  <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/2 bg-white group rounded-md">
                    <input
                      {...register("alt_phone")}
                      id="alt_phone"
                      type="number"
                      className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="alt_phone"
                      className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                    >
                      Alternative Phone Number
                    </label>
                  </div>
                  <div className="mb-4 relative w-full lg:w-1/2 bg-white group rounded-md">
                    <input
                      {...register("voter_id")}
                      id="voter_id"
                      type="number"
                      className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="voter_id"
                      className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                    >
                      National ID
                    </label>
                  </div>
                </div>
                <div className="flex flex-wrap flex-row">
                  <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/2 bg-white group rounded-md">
                    <input
                      {...register("twitter_link")}
                      id="twitter_link"
                      type="text"
                      className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="twitter_link"
                      className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                    >
                      Twitter Link
                    </label>
                  </div>
                  <div className="mb-4 relative w-full lg:w-1/2 bg-white group rounded-md">
                    <input
                      {...register("facebook_link")}
                      id="facebook_link"
                      type="text"
                      className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="facebook_link"
                      className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                    >
                      facebook Link
                    </label>
                  </div>
                </div>
                <div className="flex flex-wrap flex-row">
                  <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/2 bg-white group rounded-md">
                    <input
                      {...register("linkedIn_link")}
                      id="linkedIn_link"
                      type="text"
                      className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="linkedIn_link"
                      className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                    >
                      LinkedIn Link
                    </label>
                  </div>
                  <div className="mb-4 relative w-full lg:w-1/2 bg-white group rounded-md">
                    <Controller
                      name="political_party"
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={partyOption}
                          styles={customStyles}
                          className="mt-1"
                          isSearchable={true}
                          isClearable
                          // isDisabled={res_id || vendor ? true : false}
                        />
                      )}
                      control={control}
                      rules={{ required: true }}
                    />
                    <label
                      htmlFor="political_party"
                      className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                    >
                      Political Party
                    </label>
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full px-8 py-4 mt-9 rounded-md font-medium text-base flex items-center justify-center bg-green-500 text-white hover:bg-green-600 transition-colors duration-200 mr-4"
                >
                  Apply
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ApplyAmbassadorModal;
