import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../store/hooks';
import DistrictList from '../../../components/Admin/District/DistrictList';
import { deleteDistrict, editDistrict, getDistrictList, getDivisionList, postDistrict } from '../../../store/apis';
import DistrictCreate from '../../../components/Admin/District/DistrictCreate';

export type FormType = {
  name: string;
  division: {
    id: number;
    name: string;
  };
};

const District = () => {
  const [name, setName] = useState<string>("");
  const [tempData, setTempData] = useState<{ state: string; data: number }>({
    state: "",
    data: 0,
  });
  const [division, setDivision] = useState<{
    id: number;
    name: string;
  }>({
    id: 0,
    name: ""
  });
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getDistrictList());
    dispatch(getDivisionList())
  }, [dispatch])
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(postDistrict({ name, division_id: division.id }));
    setDivision({
      id: 0,
      name: "",
    });
    setName("");
  };
  const handleEditClick = (data: { id: number; name: string, divisionId: number, divisionName: string }) => {
    setTempData({
      state: "edit",
      data: data.id,
    });
    setDivision({
      id: data.divisionId,
      name: data.divisionName,
    });
    setName(data.name)
  };
  const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (tempData.state === "edit") {
      dispatch(editDistrict({ name: name, id: tempData.data, division_id: division.id }));
      setTempData({
        state: "",
        data: 0,
      });
      setDivision({
        id: 0,
        name: "",
      });
      setName("");
    }
  };
  const handleDeleteClick = (data: { id: number }) => {
    dispatch(deleteDistrict({ id: data.id }));
  };
  return (
    <div className="flex flex-col gap-4 px-4 pt-6 text-start justify-center">
      <DistrictCreate
        name={name}
        setName={setName}
        division={division}
        setDivision={setDivision}
        handleSubmit={handleSubmit}
        handleEditSubmit={handleEditSubmit}
        tempData={tempData}
      />
      <DistrictList
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
      />
    </div>
  );
}

export default District