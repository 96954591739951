import React from 'react'

type PropsType = {
  handleSubmit(e: React.FormEvent<HTMLFormElement>): void;
  handleEditSubmit(e: React.FormEvent<HTMLFormElement>): void;
  divisionData: string;
  setDivisionData: React.Dispatch<React.SetStateAction<string>>;
  tempData: {
    state: string;
    data: number;
  };
};

const DivisionCreate = (props: PropsType) => {
  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-gray-800 w-full">
      <div className="border-b border-stroke py-4 px-6 dark:border-strokedark">
        <h3 className="font-semibold text-black dark:text-white">
          Create Division
        </h3>
      </div>
      <form onSubmit={props.tempData.state === 'edit' ? props.handleEditSubmit : props.handleSubmit}>
        <div className="p-6">
          <div className="mb-4 flex gap-6 items-end">
            <div className="w-full xl:w-1/2">
              <label className="mb-[10px] block text-black dark:text-white">
                Division name
              </label>
              <input
                value={props.divisionData}
                onChange={(e) => props.setDivisionData(e.target.value)}
                type="text"
                placeholder="Enter division name"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="w-full xl:w-1/2">
              <button className="flex w-full xl:w-1/2 justify-center rounded bg-theme_color py-3.5 px-5 font-medium text-white">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default DivisionCreate