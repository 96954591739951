import { Link } from "react-router-dom";
import LogoImg from "../../images/logo/Logo.png";

const AuthLayoutHeader = () => {
  return (
    <header className="header_area bg-white shadow-theme_shadow py-4 sticky top-0 z-[999]">
      <div className="container-fluid mx-auto">
        <div className="flex justify-center">
          <div className="brand_logo">
            <Link
              to="/"
              className="flex items-center gap-1 text-2xl font-semibold font-poppins group"
            >
              <img className="w-[36px] h-[36px]" src={LogoImg} alt="" />
              <span className="text-green-500 group-hover:text-opacity-50 transition-all md:w-5 lg:w-full overflow-hidden">
                Amar
              </span>
              <span className="text-green-500 text-opacity-50 group-hover:text-opacity-100 transition-all md:w-[14px] lg:w-full overflow-hidden">
                Neta
              </span>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}

export default AuthLayoutHeader