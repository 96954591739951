import { useAppSelector } from "../../../store/hooks";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";

type DivisionType = {
  id: number;
  name: string;
};

type PropsType = {
  handleEditClick(data: { id: number; name: string }): void;
  handleDeleteClick(data: { id: number }): void;
};

const DivisionListContent = (props: PropsType) => {
  const { division } = useAppSelector((state) => state.common);
  return (
    <div className="mt-4 rounded-md shadow-bmpshadow e-profile">
      {division?.length > 0 ? (
        division?.map((item: DivisionType, index: number) => (
          <div className="table-item md:block flex" key={index}>
            <ul
              className={`md:w-full w-2/5 overview_list md:flex items-center px-4 py-3 bg-gradient-to-tl from-[#028FF0]/10 to-[#0DC1D9]/10 bg-opacity-80 rounded-tl-md rounded-tr-md ${
                index > 0 ? "md:hidden" : ""
              }`}
            >
              <li>
                <span className="text-black lg:text-base text-sm font-light text-opacity-70">
                  Serial No.
                </span>
              </li>
              <li>
                <span className="text-black lg:text-base text-sm font-light text-opacity-70">
                  Name
                </span>
              </li>
              <li>
                <span className="text-black lg:text-base text-sm font-light text-opacity-70">
                  Action
                </span>
              </li>
            </ul>
            <ul className="overview_list md:w-full w-3/5 md:flex items-center px-4 py-3 md:border-b border-[#9D9D9D]">
              <li>
                <span className="lg:text-base text-sm text-black font-normal text-opacity-80">
                  {index + 1}
                </span>
              </li>
              <li>
                <span className="lg:text-base text-sm text-black font-normal text-opacity-80 max-w-[180px] truncate inline-block">
                  {item?.name}
                </span>
              </li>
              <li>
                <span className="lg:text-base text-sm text-black font-normal text-opacity-80 max-w-[180px] truncate inline-block">
                  <button
                    onClick={() =>
                      props.handleEditClick({ id: item.id, name: item?.name })
                    }
                  >
                    <AiOutlineEdit className="mr-2 text-yellow-500" />
                  </button>
                  <button
                    onClick={() =>
                      props.handleDeleteClick({ id: item.id})
                    }
                  >
                    <RiDeleteBin6Line className="mx-2 text-red-500" />
                  </button>
                </span>
              </li>
            </ul>
          </div>
        ))
      ) : (
        <div className="min-h-[500px] flex items-center justify-center">
          <div className="empty_text text-center">
            {/* <img className={EmptyImg} alt="" /> */}
            <p className="text-[#8d8d8d] lg:text-xl md:text-lg text-base font-normal font-archivo mt-4">
              Empty Division
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DivisionListContent;
