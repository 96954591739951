import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../settings/config";

export const getDivisionList = createAsyncThunk(
  "division/divisionList",
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/v1/division/`
      );
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getDistrictList = createAsyncThunk(
  "division/districtList",
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/v1/district/`
      );
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getUpazilaList = createAsyncThunk(
  "division/upazilaList",
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API_URL}/v1/upazila/`);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getSeatList = createAsyncThunk(
  "division/seatList",
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API_URL}/v1/seat/`);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const postDivision = createAsyncThunk(
  "postData",
  async (data: { name: string }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${API_URL}/v1/division/`,
        data
      );
      console.log(response);
      thunkAPI.dispatch(getDivisionList()); // You should dispatch getDivisionList here
      return response.data; // Return the data from the response
    } catch (error) {
      console.log(error);
      throw error; // Rethrow the error so it can be handled by the rejected action
    }
  }
);

export const editDivision = createAsyncThunk(
  "postData",
  async (data: { name: string, id: number }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${API_URL}/v1/division/${data.id}/`,
        data
      );
      thunkAPI.dispatch(getDivisionList()); // You should dispatch getDivisionList here
      return response.data; // Return the data from the response
    } catch (error) {
      console.log(error);
      throw error; // Rethrow the error so it can be handled by the rejected action
    }
  }
);

export const deleteDivision = createAsyncThunk(
  "deleteData",
  async (data: { id: number }, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${API_URL}/v1/division/${data.id}/`);
      thunkAPI.dispatch(getDivisionList()); // You should dispatch getDivisionList here
      return response.data; // Return the data from the response
    } catch (error) {
      console.log(error);
      throw error; // Rethrow the error so it can be handled by the rejected action
    }
  }
);

export const postDistrict = createAsyncThunk(
  "postDistrict",
  async (data: { name: string; division_id: number }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${API_URL}/v1/district/`,
        data
      );
      console.log(response);
      thunkAPI.dispatch(getDistrictList()); // You should dispatch getDivisionList here
      return response.data; // Return the data from the response
    } catch (error) {
      console.log(error);
      throw error; // Rethrow the error so it can be handled by the rejected action
    }
  }
);

export const editDistrict = createAsyncThunk(
  "postDistrict",
  async (data: { name: string; id: number, division_id: number }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${API_URL}/v1/district/${data.id}/`,
        data
      );
      console.log(response);
      thunkAPI.dispatch(getDistrictList()); // You should dispatch getDivisionList here
      return response.data; // Return the data from the response
    } catch (error) {
      console.log(error);
      throw error; // Rethrow the error so it can be handled by the rejected action
    }
  }
);

export const deleteDistrict = createAsyncThunk(
  "postDistrict",
  async (data: { id: number }, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${API_URL}/v1/district/${data.id}/`);
      thunkAPI.dispatch(getDistrictList()); // You should dispatch getDivisionList here
      return response.data; // Return the data from the response
    } catch (error) {
      console.log(error);
      throw error; // Rethrow the error so it can be handled by the rejected action
    }
  }
);

export const postUpazila = createAsyncThunk(
  "postUpazila",
  async (
    data: { name: string; division: number; district: number },
    thunkAPI
  ) => {
    try {
      const response = await axios.post(
        `${API_URL}/v1/upazila/`,
        data
      );
      console.log(response);
      thunkAPI.dispatch(getUpazilaList()); // You should dispatch getDivisionList here
      return response.data; // Return the data from the response
    } catch (error) {
      console.log(error);
      throw error; // Rethrow the error so it can be handled by the rejected action
    }
  }
);
