import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAdminAmbassadorList } from "../../../store/API/UserApis";
import Table from "../../../components/common/TableComponent";
import Pagination from "../../../components/common/Pagination";
import {
  ambassadorApprove,
} from "../../../store/API/MemberApis";
import AmbassadorApproveModal from "../../../components/Admin/Ambassador/AmbassadorApproveModal";

const AmbassadorUsers = () => {
  const { adminAmbassadorList } = useAppSelector((state) => state.auth);

  const [searchValue, setSearchValue] = useState("");
  const [remountComponent, setRemountComponent] = useState(0);
  const [status, setStatus] = useState("approved");
  const [openAmbassadorModal, setOpenAmbassadorModal] = useState(false);
  const [approvalItems, setApprovalItems] = useState(null)

  const closeAmbassadorModel = () => {
    setOpenAmbassadorModal(false);
  };
  const dispatch = useAppDispatch();
  const limit = 10;
  useEffect(() => {
    dispatch(
      getAdminAmbassadorList({
        page: 1,
        page_size: limit,
        user_type: "approved",
      })
    );
  }, [dispatch]);

  // const handleEditClick = (data: any) => {
  //   // Handle edit logic
  // };

  // const handleDeleteClick = (id: number) => {
  //   // Handle delete logic
  // };
  const handleApproveAmbassador = (data: any) => {
    // Handle delete logic
    if (status === "approved") {
      setOpenAmbassadorModal(true)
      setApprovalItems(data)
    } else {
      dispatch(
        ambassadorApprove({
          user_id: data?.id,
          member_id: data?.request_ambassadors?.id,
          user_type: status,
          search_key: searchValue,
        })
      );
    }
  };

  console.log(adminAmbassadorList);

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      dispatch(
        getAdminAmbassadorList({
          page: 1,
          page_size: limit,
          search_key: searchValue,
          user_type: status,
        })
      );
    }
    setRemountComponent(Math.random());
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(e.target.value);
    dispatch(
      getAdminAmbassadorList({
        page: 1,
        page_size: limit,
        user_type: e.target.value,
      })
    );
  };

  const handlePageClick = (data: any) => {
    getAdminAmbassadorList({
      page: data.selected + 1,
      page_size: limit,
      search_key: searchValue,
    });
    // setSelectedPage(data.selected + 1);
  };

  // Utility function to get all member names
  const getAllMemberNames = (members: any[]) => {
    return members.map((member) => member.name).join(", ");
  };
  const columns = [
    { header: "Name", accessor: "full_name" },
    { header: "Phone", accessor: "phone_number" },
    // { header: "Neta", accessor: (item: any) => getAllMemberNames(item.member) },
    {
      header: "Request Member",
      accessor: (item: any) =>
        status === "approved"
          ? getAllMemberNames(item.member) || "N/A"
          : item.request_ambassadors?.name || "N/A",
    },
  ];

  return (
    <div className="flex flex-col gap-4 px-4 pt-6 text-start justify-center">
      <div className="m-3">
        <p className="text-center">Ambassador List</p>
        <div className="flex items-center">
          {/* search bar */}
          <div className="relative md:w-1/3 my-2">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 ">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={handleSearch}
              id="simple-search"
              className=" border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-900 focus:border-gray-900 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  "
              placeholder="Search here.."
            />
          </div>
          {/*  status select box*/}
          <div className="mx-2">
            <div className="flex justify-center">
              <div className="">
                <select
                  value={status}
                  onChange={handleStatusChange}
                  className="form-select 
      text-gray-700
      bg-white 
      border border-solid border-gray-300
      rounded
      focus:ring-gray-900 focus:border-gray-900 h-11"
                  aria-label="Default select example"
                >
                  <option value="approved">Approved</option>
                  <option value="request">Request</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          data={
            status === "approved"
              ? adminAmbassadorList?.results?.map((item: any) => ({
                  ...item,
                  status: true,
                })) || []
              : adminAmbassadorList?.results?.map((item: any) => ({
                  ...item,
                  status: false,
                })) || []
          }
          extraAction={handleApproveAmbassador}
        />
        <div className="mt-8 flex justify-end" key={remountComponent}>
          <Pagination
            pageCount={
              adminAmbassadorList?.count &&
              Math.ceil(adminAmbassadorList?.count / limit)
            }
            handlePageClick={handlePageClick}
            pageRange={2}
          />
        </div>
      </div>
      <AmbassadorApproveModal
        openAmbassadorModal={openAmbassadorModal}
        closeAmbassadorModel={closeAmbassadorModel}
        data={approvalItems}
        search_key={searchValue}
        user_type={status}
      />
    </div>
  );
};

export default AmbassadorUsers;
