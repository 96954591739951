import { Link } from "react-router-dom";
import post5 from "../../../images/news/post/05.avif";
import { useAppSelector } from "../../../store/hooks";
import { TopNewsType } from "../../../models";

const NewsArea = () => {
  const {topNews} = useAppSelector(state => state.common)
  return (
    <section className="news_area lg:py-10 py-8 text-start">
      <div className="container-fluid mx-auto">
        <div className="grid grid-cols-12 md:gap-6 gap-4">
          <div className="xl:col-span-3 col-span-6 flex flex-col gap-4 xl:order-1 order-last">
            {topNews
              ?.filter((item: TopNewsType) => item.position === 1)
              ?.map((news: TopNewsType) => (
                <div className="news_post group" key={news?.id}>
                  <Link to={`/news/details/${news?.id}`}>
                    <div className="post_img">
                      <img
                        className="rounded-md w-full"
                        src={news?.image}
                        alt=""
                      />
                    </div>
                    <div className="post-content pt-3">
                      <h4 className="text-black lg:text-xl text-lg font-medium group-hover:text-green-500 transition-all">
                        {news?.title}
                      </h4>
                    </div>
                  </Link>
                </div>
              ))}

            <ul className="flex flex-col gap-4 divide-y border-t pt-4">
              {topNews
                ?.filter((item: TopNewsType) => item.position === 2)
                ?.map((news: TopNewsType) => (
                  <li key={news?.id}>
                    <Link
                      to={`/news/details/${news?.id}`}
                      className="flex lg:flex-row flex-col gap-2 group"
                    >
                      <img
                        className="xl:w-[120px] w-20 xl:h-[80px] h-16 rounded-md"
                        src={news.image}
                        alt=""
                      />
                      <h6 className="text-black lg:text-base text-sm font-medium group-hover:text-green-500 transition-all">
                        {news.title}
                      </h6>
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
          <div className="xl:col-span-6 col-span-12 xl:order-2 order-1">
            {topNews
              ?.filter((item: TopNewsType) => item.position === 3)
              ?.map((news: TopNewsType) => (
                <div className="news_post group" key={news?.id}>
                  <Link to={`/news/details/${news?.id}`}>
                    <div className="post_img">
                      <img
                        className="rounded-md w-full"
                        src={news.image}
                        alt=""
                      />
                    </div>
                    <div className="post-content pt-3">
                      <h4 className="text-black lg:text-xl text-lg font-medium group-hover:text-green-500 transition-all">
                        {news.title}
                      </h4>
                    </div>
                  </Link>
                </div>
              ))}
            <ul className="flex flex-col gap-4 divide-y pt-4 border-t mt-4">
              {topNews
                ?.filter((item: TopNewsType) => item.position === 4)
                ?.map((news: TopNewsType) => (
                  <li key={news?.id}>
                    <Link
                      to={`/news/details/${news?.id}`}
                      className="flex lg:flex-row flex-col gap-2 group"
                    >
                      <img
                        className="w-20 h-14 rounded-md"
                        src={news.image}
                        alt=""
                      />
                      <h6 className="text-black lg:text-base text-sm font-medium group-hover:text-green-500 transition-all">
                        {news?.title}
                      </h6>
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
          <div className="xl:col-span-3 col-span-6 flex flex-col gap-4 xl:order-3 order-2">
            <div className="add_post">
              <img className="rounded-md w-full" src={post5} alt="" />
            </div>
            <div className="news_post group">
              {topNews
                ?.filter((item: TopNewsType) => item.position === 5)
                ?.map((news: TopNewsType) => (
                  <Link to={`/news/details/${news?.id}`} key={news?.id}>
                    <div className="post_img">
                      <img
                        className="rounded-md w-full"
                        src={news?.image}
                        alt=""
                      />
                    </div>
                    <div className="post-content pt-3">
                      <h4 className="text-black lg:text-xl text-lg font-medium group-hover:text-green-500 transition-all">
                        {news?.title}
                      </h4>
                    </div>
                  </Link>
                ))}
            </div>
            <ul className="flex flex-col gap-4 divide-y border-t">
              {topNews
                ?.filter((item: TopNewsType) => item.position === 6)
                ?.map((news: TopNewsType) => (
                  <li key={news?.id}>
                    <Link
                      to={`/news/details/${news?.id}`}
                      className="flex lg:flex-row flex-col gap-2 group pt-4"
                    >
                      <img
                        className="xl:w-[120px] w-20 xl:h-[80px] h-16 rounded-md"
                        src={news?.image}
                        alt=""
                      />
                      <h6 className="text-black lg:text-base text-sm font-medium group-hover:text-green-500 transition-all">
                        {news.title}
                      </h6>
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewsArea