import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../settings/config";
import { getAdminAmbassadorList } from "./UserApis";

export const getMostQuestionMember = createAsyncThunk(
  "member/question",
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/v1/member/most_question`
      );
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getMostAnswerMember = createAsyncThunk(
  "question/member",
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API_URL}/v1/member/most_answer`);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getMemberDetails = createAsyncThunk(
  "details/member",
  async (id: string | number, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API_URL}/v1/member/details/${id}`);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getMemberList= createAsyncThunk(
  "member/list",
  async (paramsData: any, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API_URL}/v1/member/search`, {
        params: paramsData
      });
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);


export const ambassadorApprove = createAsyncThunk(
  "member/list",
  async (paramsData: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/v1/member/ambassador-approved/`,
        {
          params: paramsData,
        }
      );
      dispatch(getAdminAmbassadorList(paramsData));
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const ambassadorRemove = createAsyncThunk(
  "member/list",
  async (paramsData: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/v1/member/ambassador-removed/`,
        {
          params: paramsData,
        }
      );
      dispatch(getAdminAmbassadorList(paramsData));
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const ambassadorSuggestion = createAsyncThunk(
  "member/suggestions",
  async (paramsData: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.get(`${API_URL}/v1/member/suggestions/`, {
        params: paramsData,
      });
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
