

import { useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { TopNewsType } from "../../models";
import { Link } from "react-router-dom";

const NewsDetailsSuggestions = () => {
  const {batchNews, topNews} = useAppSelector(state => state.common)
  const [toggleTab, setToggleTab] = useState(1)
  return (
    <div className="lg:col-span-4 col-span-12">
      <div>
        <ul className="flex items-center gap-4">
          <li>
            <button
              onClick={() => setToggleTab(1)}
              className={`text-black text-base font-medium ${
                toggleTab === 1
                  ? "text-green-500 border-b border-green-500 font-semibold"
                  : "hover:text-green-500"
              }`}
            >
              পঠিত
            </button>
          </li>
          <li>
            <button
              onClick={() => setToggleTab(2)}
              className={`text-black text-base font-medium ${
                toggleTab === 2 ? "activeClasses" : "inactiveClasses"
              }`}
            >
              নির্বাচিত
            </button>
          </li>
        </ul>
        <div className="bg-white shadow-theme_shadow p-3 rounded-md mt-4">
          {toggleTab === 1 && (
            <div className="flex flex-col gap-4">
              {batchNews?.map((item: TopNewsType) => (
                <Link
                  to={`/news/details/${item.id}`}
                  className="post_item flex gap-3"
                  key={item?.id}
                >
                  <img
                    className="w-[100px] h-[60px]"
                    src={item?.image}
                    alt=""
                  />
                  <div className="post_content">
                    <p className="text-black text-sm font-normal text-opacity-70">
                      {item?.title}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          )}
          {toggleTab === 2 && (
            <div className="flex flex-col gap-4">
              {topNews?.map((item: TopNewsType) => (
                <Link
                  to={`/news/details/${item.id}`}
                  className="post_item flex gap-3"
                >
                  <img
                    className="w-[100px] h-[60px]"
                    src={item?.image}
                    alt=""
                  />
                  <div className="post_content">
                    <p className="text-black text-sm font-normal text-opacity-70">
                      {item?.title}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsDetailsSuggestions;
