

import SearchNeta from "../../../components/Home/SearchNeta";
import ListSidebar from "../../../components/Neta/List/ListSidebar";
import ListContent from "../../../components/Neta/List/ListContent";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getMemberList } from "../../../store/API/MemberApis";
import Pagination from "../../../components/common/Pagination";
import { getGeneralSettings } from "../../../store/API/CommonApis";
import ListBanner from "../../../components/Neta/List/ListBanner";

const NetaList = () => {
  const { memberList } = useAppSelector(state => state.member)
  const [remountComponent, setRemountComponent] = useState(0);
  const navigate = useNavigate();
  
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch()
  const limit = 4;
  useEffect(() => {
    if (searchParams.get("seat")) {
      dispatch(
        getMemberList({
          page: 1,
          page_size: limit,
          seat: searchParams.get("seat"),
        })
      );
    } else if (searchParams.get("upazila")) {
      dispatch(
        getMemberList({
          page: 1,
          page_size: limit,
          upazila: searchParams.get("upazila"),
        })
      );
    } else if (searchParams.get("district")) {
      dispatch(
        getMemberList({
          page: 1,
          page_size: limit,
          district: searchParams.get("district"),
        })
      );
    } else if (searchParams.get("division")) {
      dispatch(
        getMemberList({
          page: 1,
          page_size: limit,
          division: searchParams.get("division"),
        })
      );
    } else {
      dispatch(
        getMemberList({
          page: 1,
          page_size: limit,
        })
      );
    }
  }, [searchParams, dispatch]);
  useEffect(() => {
    dispatch(getGeneralSettings());
  }, [dispatch]);
  const handlePageClick = (data: any) => {
    if (searchParams.get("seat")) {
      dispatch(
        getMemberList({
          page: data.selected + 1,
          page_size: limit,
          seat: searchParams.get("seat"),
        })
      );
    } else if (searchParams.get("upazila")) {
      dispatch(
        getMemberList({
          page: data.selected + 1,
          page_size: limit,
          upazila: searchParams.get("upazila"),
        })
      );
    } else if (searchParams.get("district")) {
      dispatch(
        getMemberList({
          page: data.selected + 1,
          page_size: limit,
          district: searchParams.get("district"),
        })
      );
    } else if (searchParams.get("division")) {
      dispatch(
        getMemberList({
          page: data.selected + 1,
          page_size: limit,
          division: searchParams.get("division"),
        })
      );
    } else {
      dispatch(
        getMemberList({
          page: data.selected + 1,
          page_size: limit,
        })
      );
    }
    window.scrollTo({
      top: 180,
      behavior: "smooth",
    });
  };
  const handleMemberTypeClick = (data:any) => { 
    dispatch(
      getMemberList({
        page: 1,
        page_size: limit,
        division: searchParams.get("division"),
        member_type: data,
      })
    );
  }
  const handleSearchSubmit = (data: any) => {
    
    if (data?.seat) {
      navigate(`/neta/list?seat=${data?.seat?.id}`);
    } else if (data?.upazila) {
      navigate(`/neta/list?upazila=${data?.upazila?.value}`);
    } else if (data?.district) {
      navigate(`/neta/list?district=${data?.district?.value}`);
    } else if (data?.division) {
      navigate(`/neta/list?division=${data?.division?.value}`);
    }
    setRemountComponent(Math.random());
  };
  return (
    <>
      <ListBanner />
      <SearchNeta handleSearchSubmit={handleSearchSubmit} />
      <section className="neta_list bg-[#fafbff] lg:py-10 py-8">
        <div className="container-fluid mx-auto">
          <div className="flex md:flex-row flex-col items-start gap-5">
            <ListSidebar handleMemberTypeClick={handleMemberTypeClick} />
            <ListContent />
          </div>
          <div className="mt-8 flex justify-end" key={remountComponent}>
            <Pagination
              pageCount={
                memberList?.count && Math.ceil(memberList?.count / limit)
              }
              handlePageClick={handlePageClick}
              pageRange={5}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default NetaList;
