import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { MemberType } from "../../../models";

const AnswerArea = () => {
  const { mostAnswerMember } = useAppSelector((state) => state.member);
  return (
    <section className="answer_area lg:pb-10 pb-8">
      <div className="container-fluid mx-auto">
        <div className="section_header pb-8 flex flex-wrap items-center justify-between gap-5">
          <h3 className="inline-block px-5 text-black xl:text-4xl lg:text-2xl md:text-xl text-lg font-medium relative before:block before:absolute before:top-1/2 before:left-0 before:w-[100px] before:h-[50px] before:bg-green-500 before:-z-10 before:-skew-x-[20deg] before:-translate-y-1/2">
            Neta Gave Most Answers
          </h3>
          <Link
            to={"/"}
            className="inline-block bg-green-600 p-2 w-[200px] text-center rounded-md text-white md:text-base text-sm font-normal"
          >
            More Answer
          </Link>
        </div>
        <div className="grid 2xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 md:gap-6 gap-3">
          {mostAnswerMember?.map((item: MemberType) => (
            <div
              key={item.id}
              className="ans_item relative bg-white shadow-theme_shadow px-3 py-8 rounded-md text-center group hover:scale-[1.05] transition-all"
            >
              <div className="item_thumb h-[100px]">
                <Link to={`/neta/details/${item?.id}`}>
                  <img
                    className="h-full object-contain border-2 border-green-500 mx-auto rounded-full"
                    src={item.profile_pic}
                    alt=""
                  />
                </Link>
              </div>
              <div className="item_text pt-5">
                <Link to={`/neta/details/${item?.id}`}>
                  <h6 className="text-green-500 md:text-base text-sm font-normal line-clamp-2">
                    {item.name}
                  </h6>
                </Link>
                <Link to={`/neta/details/${item?.id}`}>
                  <span className="status inline-block text-[#838383] text-sm font-normal">
                    {item.designation}
                  </span>
                </Link>
                <Link
                  to={`/neta/details/${item?.id}`}
                  className="text-green-500 text-opacity-70 text-sm font-normal block pt-2 group-hover:text-red-400 transition-all"
                >
                  {item.num_answers} Answers
                </Link>
              </div>
              <div className="card_border">
                <span className="inline-block absolute bg-green-500 z-10 transition-all duration-[.3s] w-full h-[1px] top-0 left-0 origin-left scale-x-0 group-hover:scale-x-100"></span>
                <span className="inline-block absolute bg-green-500 z-10 transition-all duration-[.3s] w-[1px] h-full top-0 right-0 origin-top scale-y-0 group-hover:scale-y-100 group-hover:delay-[.3s]"></span>
                <span className="inline-block absolute bg-green-500 z-10 transition-all duration-[.3s] w-full h-[1px] bottom-0 left-0 origin-right scale-x-0 group-hover:scale-x-100 group-hover:delay-[.6s]"></span>
                <span className="inline-block absolute bg-green-500 z-10 transition-all duration-[.3s] w-[1px] h-full top-0 left-0 origin-bottom scale-y-0 group-hover:scale-y-100 group-hover:delay-[.9s]"></span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AnswerArea;
