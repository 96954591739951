import { useEffect, useState } from "react";
import VideoCard from "./VideoCard";
import VideoModal from "../../common/VideoModal";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getGeneralSettings, getVideos } from "../../../store/API/CommonApis";
import { VideosType } from "../../../models";

const Banner = () => {
  const {generalSettings, topVideos} = useAppSelector(state => state.common)
    const [video, setVideo] = useState<{
      title: string;
      src: string;
    }>({
      title: "",
      src: "",
    });
  const [open, setIsOpen] = useState<boolean>(false);
  const closeModal = () => {
    setIsOpen(false);
  }
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getGeneralSettings());
    dispatch(getVideos({ category: 'TOP', limit: 4 }));
  }, [dispatch]);
  return (
    <section className="banner_area pt-4">
      <div className="container-fluid mx-auto">
        <div className="grid grid-flow-row grid-cols-12 sm:gap-6 gap-3">
          <div className="lg:col-span-8 col-span-12 row-span-2">
            <div className="banner_thumb w-full h-full aspect-w-4 lg:aspect-h-1 aspect-h-2">
              <img
                src={generalSettings?.banner}
                className="absolute inset-0 rounded-lg"
                alt=""
              />
            </div>
          </div>
          <div className="lg:col-span-4 col-span-12 row-span-2 bg-white shadow-theme_shadow rounded-md border-2 border-red-500">
            <div className="flex flex-wrap">
              {topVideos
                ?.filter((video: VideosType) => video.position === 1)
                .map((vData: VideosType) => (
                  <VideoCard
                    key={vData?.id}
                    styleCss={
                      "w-[calc(100%_/_2)] sm:p-4 p-3 relative after:absolute after:block after:top-1/2 after:right-0 after:-translate-y-1/2 after:w-[1px] after:h-[calc(100%_-_40px)] after:bg-red-500 before:absolute before:bottom-0 before:left-1/2 before:-translate-y-1/2 before:-translate-x-1/2 before:w-[calc(100%_-_40px)] before:h-[.1px] before:bg-red-500"
                    }
                    vData={vData}
                    video={video}
                    open={open}
                    setIsOpen={setIsOpen}
                    setVideo={setVideo}
                  />
                ))}

              {topVideos
                ?.filter((video: VideosType) => video.position === 2)
                .map((vData: VideosType) => (
                  <VideoCard
                    key={vData?.id}
                    styleCss={
                      "w-[calc(100%_/_2)] sm:p-4 p-3 relative before:absolute before:bottom-0 before:left-1/2 before:-translate-y-1/2 before:-translate-x-1/2 before:w-[calc(100%_-_40px)] before:h-[.1px] before:bg-red-500"
                    }
                    vData={vData}
                    video={video}
                    open={open}
                    setIsOpen={setIsOpen}
                    setVideo={setVideo}
                  />
                ))}
              {topVideos
                ?.filter((video: VideosType) => video.position === 3)
                .map((vData: VideosType) => (
                  <VideoCard
                    key={vData?.id}
                    styleCss={
                      "w-[calc(100%_/_2)] sm:p-4 p-3 relative after:absolute after:block after:top-1/2 after:right-0 after:-translate-y-1/2 after:w-[1px] after:h-[calc(100%_-_40px)] after:bg-red-500"
                    }
                    vData={vData}
                    video={video}
                    open={open}
                    setIsOpen={setIsOpen}
                    setVideo={setVideo}
                  />
                ))}
              {topVideos
                ?.filter((video: VideosType) => video.position === 4)
                .map((vData: VideosType) => (
                  <VideoCard
                    key={vData?.id}
                    styleCss={"w-[calc(100%_/_2)] sm:p-4 p-3"}
                    vData={vData}
                    video={video}
                    open={open}
                    setIsOpen={setIsOpen}
                    setVideo={setVideo}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <VideoModal open={open} closeModal={closeModal} video={video} />
    </section>
  );
}

export default Banner