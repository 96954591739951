import React from 'react'

const SignaturesAllSignatures = () => {
  return (
    <div className="bg-white p-6 rounded-md shadow-theme_shadow mt-4">
      <h4 className="text-black text-opacity-70 xl:text-3xl md:text-2xl text-xl font-semibold pb-4">
        All Signatures
      </h4>
      <ul className="table_pagination flex items-center gap-3">
        <li>
          <button
            type="button"
            className="text-black text-opacity-40 text-sm font-normal"
            disabled
          >
            Go Back
          </button>
        </li>
        <li>
          <button type="button" className="text-black text-sm font-normal">
            1 Of 32
          </button>
        </li>
        <li>
          <button type="button" className="text-black text-sm font-normal">
            Go Next
          </button>
        </li>
      </ul>
      <div className="relative overflow-x-auto mt-4">
        <table className="w-full text-sm text-left 0">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="px-6 py-3 bg-gray-50">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Option
              </th>
              <th scope="col" className="px-6 py-3 bg-gray-50">
                Constituency
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-gray-200">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-green-500 whitespace-nowrap bg-gray-50"
              >
                Md Jubair Mahamud Protim
              </th>
              <td className="px-6 py-4 min-w-[250px]">
                সুষ্ঠু তদন্তের মাধ্যমে আমরা এর সঙ্গে জড়িত সকলের বিচার চাই।
              </td>
              <td className="px-6 py-4 bg-gray-50">Kustia-3</td>
              <td className="px-6 py-4">06/05/2023</td>
            </tr>
            <tr className="border-b border-gray-200">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-green-500 whitespace-nowrap bg-gray-50"
              >
                Md Jubair Mahamud Protim
              </th>
              <td className="px-6 py-4 min-w-[250px]">
                সুষ্ঠু তদন্তের মাধ্যমে আমরা এর সঙ্গে জড়িত সকলের বিচার চাই।
              </td>
              <td className="px-6 py-4 bg-gray-50">Kustia-3</td>
              <td className="px-6 py-4">06/05/2023</td>
            </tr>
            <tr className="border-b border-gray-200">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-green-500 whitespace-nowrap bg-gray-50"
              >
                Md Jubair Mahamud Protim
              </th>
              <td className="px-6 py-4 min-w-[250px]">
                সুষ্ঠু তদন্তের মাধ্যমে আমরা এর সঙ্গে জড়িত সকলের বিচার চাই।
              </td>
              <td className="px-6 py-4 bg-gray-50">Kustia-3</td>
              <td className="px-6 py-4">06/05/2023</td>
            </tr>
            <tr className="border-b border-gray-200">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-green-500 whitespace-nowrap bg-gray-50"
              >
                Md Jubair Mahamud Protim
              </th>
              <td className="px-6 py-4 min-w-[250px]">
                সুষ্ঠু তদন্তের মাধ্যমে আমরা এর সঙ্গে জড়িত সকলের বিচার চাই।
              </td>
              <td className="px-6 py-4 bg-gray-50">Kustia-3</td>
              <td className="px-6 py-4">06/05/2023</td>
            </tr>
            <tr className="border-b border-gray-200">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-green-500 whitespace-nowrap bg-gray-50"
              >
                Md Jubair Mahamud Protim
              </th>
              <td className="px-6 py-4 min-w-[250px]">
                সুষ্ঠু তদন্তের মাধ্যমে আমরা এর সঙ্গে জড়িত সকলের বিচার চাই।
              </td>
              <td className="px-6 py-4 bg-gray-50">Kustia-3</td>
              <td className="px-6 py-4">06/05/2023</td>
            </tr>
            <tr className="border-b border-gray-200">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-green-500 whitespace-nowrap bg-gray-50"
              >
                Md Jubair Mahamud Protim
              </th>
              <td className="px-6 py-4 min-w-[250px]">
                সুষ্ঠু তদন্তের মাধ্যমে আমরা এর সঙ্গে জড়িত সকলের বিচার চাই।
              </td>
              <td className="px-6 py-4 bg-gray-50">Kustia-3</td>
              <td className="px-6 py-4">06/05/2023</td>
            </tr>
            <tr className="border-b border-gray-200">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-green-500 whitespace-nowrap bg-gray-50"
              >
                Md Jubair Mahamud Protim
              </th>
              <td className="px-6 py-4 min-w-[250px]">
                সুষ্ঠু তদন্তের মাধ্যমে আমরা এর সঙ্গে জড়িত সকলের বিচার চাই।
              </td>
              <td className="px-6 py-4 bg-gray-50">Kustia-3</td>
              <td className="px-6 py-4">06/05/2023</td>
            </tr>
            <tr className="border-b border-gray-200">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-green-500 whitespace-nowrap bg-gray-50"
              >
                Md Jubair Mahamud Protim
              </th>
              <td className="px-6 py-4 min-w-[250px]">
                সুষ্ঠু তদন্তের মাধ্যমে আমরা এর সঙ্গে জড়িত সকলের বিচার চাই।
              </td>
              <td className="px-6 py-4 bg-gray-50">Kustia-3</td>
              <td className="px-6 py-4">06/05/2023</td>
            </tr>
            <tr className="border-b border-gray-200">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-green-500 whitespace-nowrap bg-gray-50"
              >
                Md Jubair Mahamud Protim
              </th>
              <td className="px-6 py-4 min-w-[250px]">
                সুষ্ঠু তদন্তের মাধ্যমে আমরা এর সঙ্গে জড়িত সকলের বিচার চাই।
              </td>
              <td className="px-6 py-4 bg-gray-50">Kustia-3</td>
              <td className="px-6 py-4">06/05/2023</td>
            </tr>
            <tr className="border-b border-gray-200">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-green-500 whitespace-nowrap bg-gray-50"
              >
                Md Jubair Mahamud Protim
              </th>
              <td className="px-6 py-4 min-w-[250px]">
                সুষ্ঠু তদন্তের মাধ্যমে আমরা এর সঙ্গে জড়িত সকলের বিচার চাই।
              </td>
              <td className="px-6 py-4 bg-gray-50">Kustia-3</td>
              <td className="px-6 py-4">06/05/2023</td>
            </tr>
            <tr className="border-b border-gray-200">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-green-500 whitespace-nowrap bg-gray-50"
              >
                Md Jubair Mahamud Protim
              </th>
              <td className="px-6 py-4 min-w-[250px]">
                সুষ্ঠু তদন্তের মাধ্যমে আমরা এর সঙ্গে জড়িত সকলের বিচার চাই।
              </td>
              <td className="px-6 py-4 bg-gray-50">Kustia-3</td>
              <td className="px-6 py-4">06/05/2023</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SignaturesAllSignatures