import { Navigate } from "react-router-dom";
import auth from "./auth";


const ProtectedRoute = ({ children }: { children: JSX.Element }) => {

let isAuth = auth.getToken();
  return isAuth !== null ? children : <Navigate to="/login" />;
};

export default ProtectedRoute
