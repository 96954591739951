import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { useAppSelector } from '../../../store/hooks';

export const customStyles = {
  control: (provided: any) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "45px",
    height: "45px",
  }),
};

type PropsType = {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  division: {
    id: number;
    name: string;
  };
  setDivision: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
    }>
  >;
  handleSubmit(e: React.FormEvent<HTMLFormElement>): void;
  handleEditSubmit(e: React.FormEvent<HTMLFormElement>): void;
  tempData: { state: string; data: number };
};

const DistrictCreate = (props: PropsType) => {
    const { division } = useAppSelector(state => state.common)
    const [divisionOption, setDivisionOption] = useState<
      { value: number; label: string }[]
    >([]);
    useEffect(() => {
        let option: {value: number, label: string}[] = [];
        if (division?.length > 0) {
            division.map((item: { id: number; name: string }) =>
              option.push({ value: item.id, label: item.name })
            );
        }
        setDivisionOption(option);
    }, [division])

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-gray-800 w-full">
      <div className="border-b border-stroke py-4 px-6 dark:border-strokedark">
        <h3 className="font-semibold text-black dark:text-white">
          Create District
        </h3>
      </div>
      <form onSubmit={props.tempData.state === 'edit' ? props.handleEditSubmit : props.handleSubmit}>
        <div className="p-6">
          <div className="mb-4 flex flex-col xl:flex-row gap-6 items-end">
            <div className="w-full xl:w-1/2">
              <label className="mb-[10px] block text-black dark:text-white">
                District name
              </label>
              <input
                value={props.name}
                onChange={(e) => props.setName(e.target.value)}
                type="text"
                placeholder="Enter division name"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="w-full xl:w-1/2">
              <label className="mb-[10px] block text-black dark:text-white">
                Division name
              </label>
              <Select
                className="w-full rounded bg-transparent font-medium outline-none transition disabled:cursor-default disabled:bg-whiter"
                classNamePrefix="select"
                aria-label="Default select example"
                isSearchable={false}
                value={divisionOption.find(
                  (option) => option.value === props.division.id
                )}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    props.setDivision({
                      id: selectedOption.value,
                      name: selectedOption.label,
                    });
                  } else {
                    props.setDivision({
                      id: 0,
                      name: "",
                    });
                  }
                }}
                styles={customStyles}
                options={divisionOption}
                instanceId="long-value-select"
              />
            </div>
            <div className="w-full xl:w-1/2">
              <button className="flex w-full xl:w-1/2 justify-center rounded bg-theme_color py-3.5 px-5 font-medium text-white">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default DistrictCreate