
import { useEffect } from "react";
import NewsContent from "../../components/NewsDetails/NewsContent";
import NewsDetailsSuggestions from "../../components/NewsDetails/NewsDetailsSuggestions";
import { useAppDispatch } from "../../store/hooks";
import { getBatchNews, getNewsDetails, getTopNews } from "../../store/API/NewsApis";
import { useParams } from "react-router-dom";

const NewsDetails = () => {
  let { id } = useParams();
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (id) dispatch(getNewsDetails(id));
    dispatch(getTopNews());
    const newsExists = localStorage.getItem('news')
    const newsArray = newsExists ? JSON.parse(newsExists) : [];
    const idDoesNotExist = !newsArray.includes(id);
    if (idDoesNotExist && id) {
      localStorage.setItem("news", JSON.stringify([...newsArray, ...id]));
    }
    if (newsExists) dispatch(getBatchNews(JSON.parse(newsExists)));
       
  }, [dispatch, id])
  return (
    <>
      <section className="news_details bg-[#fafbff] lg:py-10 py-8">
        <div className="container-fluid mx-auto">
          <div className="grid grid-cols-12 gap-5">
            <NewsContent />
            <NewsDetailsSuggestions />
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsDetails;
