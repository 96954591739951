

export default function ErrorBoundary() {
    return (
      <div>
        <div>Page not found</div>
        <p>If you have right access this page, feel free to contact admin</p>
      </div>
    );
}
