import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../settings/config";
import QueryString from "qs";

export const getTopNews = createAsyncThunk(
  "news/top",
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API_URL}/v1/news/top`);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getGeneralNews = createAsyncThunk(
  "news/general",
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API_URL}/v1/news/general`);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getNewsList = createAsyncThunk(
  "news/list",
  async (
    paramsData: any,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.get(`${API_URL}/v1/news/list`, {
        params: paramsData,
      });
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getMemberNews = createAsyncThunk(
  "news/member",
  async (id: string | number, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API_URL}/v1/news/member?q=${id}`);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getNewsSuggestionByDivision = createAsyncThunk(
  "news/division",
  async (id: string | number, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/v1/news/division?division=${id}`
      );

      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);


export const getNewsDetails = createAsyncThunk(
  "news/details",
  async (id: string | number, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API_URL}/v1/news/details/${id}`);
      getNewsSuggestionByDivision(data?.division);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getBatchNews = createAsyncThunk(
  "news/batch",
  async (ids: string[] | number[], { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API_URL}/v1/news/batch`, {
        params: {
          news: ids,
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, { arrayFormat: "repeat" });
        },
      });
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);