import React from 'react'
import LogoImage from "../../images/logo/Logo.png";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="container-fluid py-12 mx-auto grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-4">
        <div className="col-span-2 md:max-w-[314px] max-w-[400px] sm:text-start text-center mx-auto sm:mx-0">
          <Link
            to="/"
            className="flex items-center justify-center md:justify-start mr-10"
          >
            <img src={LogoImage} className="mr-3 h-11 w-11" alt="naw-nft" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap">
              NeTa
            </span>
          </Link>
          <p className="my-4 text-gray-700 font-normal">
            If Bitcoin can have value if Ethereum can have value, then, in
            theory.
          </p>
          <div className="mb-4 mt-9 border-b border-gray-300"></div>
          <p className="py-1 font-medium text-gray-900">
            Get the latest NeTa updates
          </p>
          <div className="flex mt-3 mb-7 md:mb-0">
            <input
              className="py-3 px-4 rounded-l-md w-full bg-white placeholder:text-gray-700 border border-r-0 border-gray-300 focus:outline-none text-xs"
              placeholder="Type Here"
            />
            <button className="px-5 py-2 rounded-r-md font-medium text-base flex items-center justify-center bg-green-500 text-white hover:bg-red-500 transition-colors duration-200 mr-4">
              Subscribe
            </button>
          </div>
        </div>
        <div className="md:col-span-1 py-1">
          <p className="text-lg font-semibold text-gray-900 mb-1">NeTa</p>
          <ul className="text-gray-700 text-sm">
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                News{" "}
              </Link>
            </li>
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                Art{" "}
              </Link>
            </li>
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                Photography{" "}
              </Link>
            </li>
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                Music{" "}
              </Link>
            </li>
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                Virtual World{" "}
              </Link>
            </li>
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                Sports{" "}
              </Link>
            </li>
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                Utility{" "}
              </Link>
            </li>
          </ul>
        </div>
        <div className="md:col-span-1 py-1">
          <p className="text-lg font-semibold text-gray-900 mb-1">My Account</p>
          <ul className="text-gray-700 text-sm">
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                My Profile{" "}
              </Link>
            </li>
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                My Favourite{" "}
              </Link>
            </li>
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                Account Settings
              </Link>
            </li>
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                My Ranking{" "}
              </Link>
            </li>
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                My Activities{" "}
              </Link>
            </li>
          </ul>
        </div>
        <div className="md:col-span-1 py-1">
          <p className="text-lg font-semibold text-gray-900 mb-1">Resources</p>
          <ul className="text-gray-700 text-sm">
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                Blog{" "}
              </Link>
            </li>
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                Help{" "}
              </Link>
            </li>
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                Support{" "}
              </Link>
            </li>
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                FAQs{" "}
              </Link>
            </li>
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                Terms &amp; Conditions
              </Link>
            </li>
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                Privacy Policy{" "}
              </Link>
            </li>
          </ul>
        </div>
        <div className="md:col-span-1 py-1">
          <p className="text-lg font-semibold text-gray-900 mb-1">Company</p>
          <ul className="text-gray-700 text-sm">
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                About{" "}
              </Link>
            </li>
            <li className="py-1">
              <Link to="/" className="block hover:text-gray-900">
                {" "}
                Career{" "}
              </Link>
            </li>
          </ul>
          <div className="pt-6">
            <p className="text-lg font-semibold text-gray-900 mb-1">Address</p>
            <div className="flex items-center py-1">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                className="mr-2 fill-gray-700"
              >
                <path d="M10.5 6.01557C10.5 7.4004 9.38125 8.52205 8 8.52205C6.61875 8.52205 5.5 7.4004 5.5 6.01557C5.5 4.63073 6.61875 3.50908 8 3.50908C9.38125 3.50908 10.5 4.63073 10.5 6.01557ZM8 5.01297C7.44687 5.01297 7 5.46101 7 6.01557C7 6.57013 7.44687 7.01816 8 7.01816C8.55313 7.01816 9 6.57013 9 6.01557C9 5.46101 8.55313 5.01297 8 5.01297ZM14 6.01557C14 8.7539 10.3437 13.629 8.74062 15.6405C8.35625 16.1198 7.64375 16.1198 7.25938 15.6405C5.62813 13.629 2 8.7539 2 6.01557C2 2.69322 4.68625 0 8 0C11.3125 0 14 2.69322 14 6.01557ZM8 1.50389C5.51562 1.50389 3.5 3.52475 3.5 6.01557C3.5 6.40407 3.64031 7.00563 3.97812 7.80771C4.30562 8.58472 4.77063 9.44319 5.30625 10.3111C6.19375 11.7586 7.225 13.1309 8 14.1522C8.775 13.1309 9.80625 11.7586 10.6938 10.3111C11.2281 9.44319 11.6938 8.58472 12.0219 7.80771C12.3594 7.00563 12.5 6.40407 12.5 6.01557C12.5 3.52475 10.4844 1.50389 8 1.50389Z"></path>
              </svg>
              <small className="text-gray-700">
                1230, Road : 4, Hose : 145, Mohakhali Dosh, Dhaka
              </small>
            </div>
          </div>
        </div>
      </div>
      <div className="border-b border-gray-300"></div>
      <div className="container-fluid mx-auto">
        <div className="flex flex-wrap items-center justify-between">
          <small className="pt-4 pb-2 md:py-9 text-center w-full md:w-auto text-gray-700 font-normal">
            Copyright © 2023 JRK International Ltd. All Rights Reserves
          </small>
          <div className="pt-2 pb-4 md:py-9 w-full md:w-auto flex items-center justify-center">
            <Link to="/">
              <svg
                className="w-5 h-5 fill-gray-400 hover:fill-primary-500 ml-5"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7.55016 21.75C16.6045 21.75 21.5583 14.2468 21.5583 7.74192C21.5583 7.53098 21.5536 7.31536 21.5442 7.10442C22.5079 6.40752 23.3395 5.54432 24 4.55536C23.1025 4.95466 22.1496 5.21544 21.1739 5.3288C22.2013 4.71297 22.9705 3.74553 23.3391 2.60583C22.3726 3.17862 21.3156 3.58267 20.2134 3.80067C19.4708 3.01162 18.489 2.48918 17.4197 2.31411C16.3504 2.13905 15.2532 2.32111 14.2977 2.83216C13.3423 3.3432 12.5818 4.15477 12.1338 5.14137C11.6859 6.12798 11.5754 7.23468 11.8195 8.29036C9.86249 8.19215 7.94794 7.68377 6.19998 6.79816C4.45203 5.91255 2.90969 4.6695 1.67297 3.14958C1.0444 4.2333 0.852057 5.51571 1.13503 6.73615C1.418 7.9566 2.15506 9.02351 3.19641 9.72005C2.41463 9.69523 1.64998 9.48474 0.965625 9.10598V9.16692C0.964925 10.3042 1.3581 11.4066 2.07831 12.2868C2.79852 13.167 3.80132 13.7706 4.91625 13.995C4.19206 14.1932 3.43198 14.2221 2.69484 14.0794C3.00945 15.0575 3.62157 15.913 4.44577 16.5264C5.26997 17.1398 6.26512 17.4807 7.29234 17.5013C5.54842 18.8712 3.39417 19.6142 1.17656 19.6107C0.783287 19.6101 0.390399 19.586 0 19.5385C2.25286 20.9838 4.87353 21.7514 7.55016 21.75Z"></path>
              </svg>
            </Link>
            <Link to="/">
              <svg
                className="w-5 h-5 fill-gray-400 hover:fill-primary-500 ml-5"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M22.2234 0H1.77187C0.792187 0 0 0.773438 0 1.72969V22.2656C0 23.2219 0.792187 24 1.77187 24H22.2234C23.2031 24 24 23.2219 24 22.2703V1.72969C24 0.773438 23.2031 0 22.2234 0ZM7.12031 20.4516H3.55781V8.99531H7.12031V20.4516ZM5.33906 7.43438C4.19531 7.43438 3.27188 6.51094 3.27188 5.37187C3.27188 4.23281 4.19531 3.30937 5.33906 3.30937C6.47813 3.30937 7.40156 4.23281 7.40156 5.37187C7.40156 6.50625 6.47813 7.43438 5.33906 7.43438ZM20.4516 20.4516H16.8937V14.8828C16.8937 13.5562 16.8703 11.8453 15.0422 11.8453C13.1906 11.8453 12.9094 13.2937 12.9094 14.7891V20.4516H9.35625V8.99531H12.7687V10.5609H12.8156C13.2891 9.66094 14.4516 8.70938 16.1813 8.70938C19.7859 8.70938 20.4516 11.0813 20.4516 14.1656V20.4516Z"></path>
              </svg>
            </Link>
            <Link to="/">
              <svg
                className="w-5 h-5 fill-gray-400 hover:fill-primary-500 ml-5"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"></path>
              </svg>
            </Link>
            <Link to="/">
              <svg
                className="w-5 h-5 fill-gray-400 hover:fill-primary-500 ml-5"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 0C5.37527 0 0 5.37527 0 12C0 18.6248 5.37527 24 12 24C18.6117 24 24 18.6248 24 12C24 5.37527 18.6117 0 12 0ZM19.9262 5.53145C21.3579 7.27549 22.217 9.50107 22.243 11.9089C21.9046 11.8439 18.5206 11.154 15.1106 11.5835C15.0325 11.4143 14.9675 11.2321 14.8894 11.0499C14.6811 10.5554 14.4469 10.0477 14.2126 9.56618C17.9869 8.0304 19.705 5.81779 19.9262 5.53145ZM12 1.77007C14.603 1.77007 16.9848 2.74621 18.7939 4.34707C18.6117 4.60738 17.0629 6.67679 13.4186 8.04338C11.7397 4.95878 9.87855 2.43384 9.5922 2.04338C10.3601 1.86117 11.1671 1.77007 12 1.77007ZM7.63995 2.73319C7.91325 3.09761 9.73538 5.63558 11.4404 8.65508C6.65076 9.9306 2.42083 9.90458 1.96529 9.90458C2.62907 6.72885 4.77657 4.08676 7.63995 2.73319ZM1.74404 12.0131C1.74404 11.9089 1.74404 11.8048 1.74404 11.7007C2.18655 11.7136 7.15835 11.7787 12.2733 10.243C12.5727 10.8156 12.846 11.4013 13.1063 11.9869C12.9761 12.026 12.8329 12.0651 12.7028 12.1041C7.41865 13.8091 4.60738 18.4685 4.3731 18.859C2.7462 17.0499 1.74404 14.6421 1.74404 12.0131ZM12 22.256C9.6312 22.256 7.44469 21.449 5.71367 20.0954C5.89588 19.718 7.97827 15.7094 13.757 13.692C13.783 13.679 13.7961 13.679 13.8221 13.666C15.2668 17.4013 15.8525 20.5379 16.0087 21.436C14.7722 21.9696 13.4186 22.256 12 22.256ZM17.7136 20.4989C17.6096 19.8742 17.0629 16.8807 15.7223 13.1974C18.9371 12.6898 21.7484 13.5228 22.0998 13.6399C21.6573 16.4902 20.0173 18.9501 17.7136 20.4989Z"
                ></path>
              </svg>
            </Link>
            <Link to="/">
              <svg
                className="w-5 h-5 fill-gray-400 hover:fill-primary-500 ml-5"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 0C5.3724 0 0 5.3808 0 12.0204C0 17.3304 3.438 21.8364 8.2068 23.4252C8.8068 23.5356 9.0252 23.1648 9.0252 22.8456C9.0252 22.5612 9.0156 21.804 9.0096 20.802C5.6712 21.528 4.9668 19.1904 4.9668 19.1904C4.422 17.8008 3.6348 17.4312 3.6348 17.4312C2.5452 16.6872 3.7176 16.7016 3.7176 16.7016C4.9212 16.7856 5.5548 17.94 5.5548 17.94C6.6252 19.776 8.364 19.2456 9.0468 18.9384C9.1572 18.162 9.4668 17.6328 9.81 17.3328C7.146 17.0292 4.344 15.9972 4.344 11.3916C4.344 10.08 4.812 9.006 5.5788 8.166C5.4552 7.8624 5.0436 6.6396 5.6964 4.986C5.6964 4.986 6.7044 4.662 8.9964 6.2172C9.97532 5.95022 10.9853 5.81423 12 5.8128C13.02 5.8176 14.046 5.9508 15.0048 6.2172C17.2956 4.662 18.3012 4.9848 18.3012 4.9848C18.9564 6.6396 18.5436 7.8624 18.4212 8.166C19.1892 9.006 19.6548 10.08 19.6548 11.3916C19.6548 16.0092 16.848 17.0256 14.1756 17.3232C14.6064 17.694 14.9892 18.4272 14.9892 19.5492C14.9892 21.1548 14.9748 22.452 14.9748 22.8456C14.9748 23.1672 15.1908 23.5416 15.8004 23.424C18.19 22.6225 20.2672 21.0904 21.7386 19.0441C23.2099 16.9977 24.001 14.5408 24 12.0204C24 5.3808 18.6264 0 12 0Z"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer