import React from "react";
import { VideosType } from "../../../models";

type PropTypes = {
  styleCss: String;
  video: { title: string; src: string };
  open: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setVideo: React.Dispatch<
    React.SetStateAction<{
      title: string;
      src: string;
    }>
  >;
  vData: VideosType;
};

const VideoCard = (props: PropTypes) => {

  return (
    <div className={`${props.styleCss}`}>
      <div className="banner_thumb w-full h-full aspect-w-1 aspect-h-1">
        <div className="[&_[x-cloak]]:hidden" x-data="{ modalOpen: false}">
          <button
            className="relative h-full flex justify-center items-center focus:outline-none focus-visible:ring rounded-md group"
            aria-controls="modal"
            aria-label="Watch the video"
            onClick={() => {
              props.setVideo({
                src: props.vData.link,
                title:
                  "বাংলার যে উন্নত শহর ইবনে বতুতাকে মুগ্ধ করেছিল | Ibn Battuta in Bengal | Labid Rahat",
              });
              props.setIsOpen(true);
            }}
          >
            <img
              className="h-full rounded-md shadow-theme_shadow transition-shadow duration-300 ease-in-out"
              src={`https://i4.ytimg.com/vi/${props.vData.video_id}/hqdefault.jpg`}
              alt="Modal video thumbnail"
            />

            <svg
              className="absolute pointer-events-none group-hover:scale-110 transition-transform duration-300 ease-in-out"
              xmlns="http://www.w3.org/2000/svg"
              width="72"
              height="72"
            >
              <circle
                className="fill-white"
                cx="36"
                cy="36"
                r="36"
                fillOpacity=".8"
              />
              <path
                className="fill-red-500 drop-shadow-2xl"
                d="M44 36a.999.999 0 0 0-.427-.82l-10-7A1 1 0 0 0 32 29V43a.999.999 0 0 0 1.573.82l10-7A.995.995 0 0 0 44 36V36c0 .001 0 .001 0 0Z"
              />
            </svg>
          </button>

          {/* {props.video.title && (
            <>
              <div
                className="fixed inset-0 z-[50] bg-black bg-opacity-50 transition-opacity"
                aria-hidden="true"
                              onClick={() => {
                                  props.setVideo({ title: "", src: "" });
                                  console.log('sdsdsdsdsd');
                                  
                }}
              ></div>
              <div
                id="modal"
                className="fixed inset-0 z-[99999] flex px-4 md:px-6 py-6 "
                role="dialog"
                aria-modal="true"
              >
                <div className="2xl:w-[50%] xl:w-[60%] md:w-[70%] sm:w-[80%] w-[90%] mx-auto h-full flex items-center">
                  <div className="w-full max-h-full rounded-3xl shadow-2xl aspect-video bg-black overflow-hidden">
                    <iframe
                      x-show="modalOpen"
                      className="w-full md:h-[500px] sm:h-[400px] h-[350px]"
                      src={`${props.video.src}`}
                      title={`${props.video.title}`}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
