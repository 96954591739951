
import { useAppSelector } from "../../../store/hooks";
import NetaProfileQuestion from "./NetaProfileQuestion";
import NetaProfileQuestionNews from "./NetaProfileQuestionNews";
import SingleQuestionAns from "./SingleQuestionAns";

type PropsType = {
  toggleTab: number;
  paramsId?:string | null 
};

const NetaProfileDetails = (props: PropsType) => {
  const { memberDetails } = useAppSelector((state) => state.member);

  return (
    <div className="lg:col-span-9 col-span-12">
      {props.toggleTab === 1 && (
        <div
          x-show="openTab === 1"
          className="bg-white shadow-theme_shadow rounded-md p-4 flex flex-col gap-5"
        >
          <div className="basic_info">
            <h4 className="text-green-500 text-lg font-medium relative pl-7 after:absolute after:block after:left-0 after:top-1 after:w-4 after:h-4 after:bg-red-500 after:rounded-sm">
              Personal Details
            </h4>
            <div className="info_text pt-2 flex flex-col gap-3">
              <p className="text-[#4a4a4a] text-sm font-normal">
                Term - {memberDetails?.election_history?.length}
              </p>
              <p className="text-[#4a4a4a] text-sm font-normal">
                Date of Birth - {memberDetails?.birth_day}
              </p>
              <p className="text-[#4a4a4a] text-sm font-normal">
                Sex - {memberDetails?.sex}
              </p>
              <p className="text-[#4a4a4a] text-sm font-normal">
                Education - {memberDetails?.Education}
              </p>
              <p className="text-[#4a4a4a] text-sm font-normal">
                Profession - {memberDetails?.Profession}
              </p>
              <p className="text-[#4a4a4a] text-sm font-normal">
                Present Address - {memberDetails?.present_address}.
              </p>
              <p className="text-[#4a4a4a] text-sm font-normal">
                Permanent Address - {memberDetails?.permanent_address}.
              </p>
            </div>
          </div>
        </div>
      )}

      {props.toggleTab === 2 && (
        <div
          x-show="openTab === 2"
          className="bg-white shadow-theme_shadow rounded-md p-4 flex flex-col gap-5"
        >
          <div className="electoral-history">
            <h4 className="text-green-500 text-lg font-medium relative pl-7 after:absolute after:block after:left-0 after:top-1 after:w-4 after:h-4 after:bg-red-500 after:rounded-sm">
              Election History
            </h4>
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="w-1/3">Date</th>
                  <th className="w-1/3">Constituency</th>
                  <th className="w-1/3">Party</th>
                </tr>
              </thead>
              <tbody>
                {memberDetails?.election_history?.map((item: any) => (
                  <tr key={item?.id}>
                    <td className="w-1/3 text-center">{item?.elected_date}</td>
                    <td className="w-1/3 text-center">{item?.constituency}</td>
                    <td className="w-1/3 text-center">{item?.party}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {props.toggleTab === 3 && (
        <div
          x-show="openTab === 3"
          className="bg-white shadow-theme_shadow rounded-md p-5 flex flex-col gap-8"
        >
          {props?.paramsId ? (
            <SingleQuestionAns  />
          ) : (
            <NetaProfileQuestion />
          )}
        </div>
      )}

      {props.toggleTab === 4 && (
        <div
          x-show="openTab === 4"
          className="bg-white shadow-theme_shadow rounded-md p-4 flex flex-col gap-5"
        >
          <NetaProfileQuestionNews />
        </div>
      )}

      {props.toggleTab === 5 && (
        <div
          x-show="openTab === 5"
          className="bg-white shadow-theme_shadow rounded-md p-4 flex flex-col gap-5"
        >
          <div className="question_list">
            <h4 className="text-green-500 text-lg font-medium relative pl-7 pb-5 after:absolute after:block after:left-0 after:top-1 after:w-4 after:h-4 after:bg-red-500 after:rounded-sm">
              Wealth 2014 -- Continue
            </h4>

            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-sm text-[#838383] bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Question
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Asked by
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Replay Count
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-4 font-normal text-green-500 min-w-[250px]"
                    >
                      <a href="#0"> রাস্তা পাকাকরন প্রসঙ্গে </a>
                    </th>
                    <td className="px-6 py-4">Shudeb Nath</td>
                    <td className="px-6 py-4">Public</td>
                    <td className="px-6 py-4">0 Reply</td>
                    <td className="px-6 py-4">Mar 20, 2019 05:46pm</td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-4 font-normal text-green-500 min-w-[250px]"
                    >
                      <a href="#0">
                        শিক্ষা-প্রতিষ্ঠানের সামনে সড়ক নির্মাণের আবেদন
                      </a>
                    </th>
                    <td className="px-6 py-4">Arafat Hossain</td>
                    <td className="px-6 py-4">Public</td>
                    <td className="px-6 py-4">0 Reply</td>
                    <td className="px-6 py-4">Apr 16, 2019 04:22am</td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-4 font-normal text-green-500 min-w-[250px]"
                    >
                      <a href="#0" className="flex md:flex-row flex-col gap-2">
                        <i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
                            />
                          </svg>
                        </i>
                        <span>
                          চাটখিল উপজেলার পাচগাঁও ইউনিয়নের গোলাম কিবরিয়া সড়কটির
                          বেহাল দশা। কাচারী বাজার থেকে পাল্লা যাওয়ার একটি মাত্র
                          সড়ক। এটি যেন মরনফাদ।
                        </span>
                      </a>
                    </th>
                    <td className="px-6 py-4">Jamil Monsur</td>
                    <td className="px-6 py-4">Public</td>
                    <td className="px-6 py-4">0 Reply</td>
                    <td className="px-6 py-4">Mar 20, 2019 05:46pm</td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-4 font-normal text-green-500 min-w-[250px]"
                    >
                      <a href="#0">Donation to West Dealiai Nurani Madrasha</a>
                    </th>
                    <td className="px-6 py-4">Md. Nizam Uddin</td>
                    <td className="px-6 py-4">Public</td>
                    <td className="px-6 py-4">2 Reply</td>
                    <td className="px-6 py-4">Jun 2, 2017 09:40am</td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-4 font-normal text-green-500 min-w-[250px]"
                    >
                      <a href="#0"> বিদ্যুৎয়ানের জন্য আবেদন। </a>
                    </th>
                    <td className="px-6 py-4">Monir Hasan</td>
                    <td className="px-6 py-4">Public</td>
                    <td className="px-6 py-4">1 Reply</td>
                    <td className="px-6 py-4">Mar 20, 2017 09:40pm</td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-4 font-normal text-green-500 min-w-[250px]"
                    >
                      <a href="#0">
                        LGED এর অন্তর্ভুক্ত রাস্তা কাঁচা রাস্তা পাকাকরণ
                      </a>
                    </th>
                    <td className="px-6 py-4">Anisur Rahman</td>
                    <td className="px-6 py-4">Public</td>
                    <td className="px-6 py-4">0 Reply</td>
                    <td className="px-6 py-4">Apr 13, 2019 05:09pm</td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-4 font-normal text-green-500 min-w-[250px]"
                    >
                      <a href="#0">
                        প্রসঙ্গ: আউটসোর্সিং ট্রেনিং সেন্টার ও ডিজিটাল ল্যাব
                        স্থাপন
                      </a>
                    </th>
                    <td className="px-6 py-4">Rashidul Hasan</td>
                    <td className="px-6 py-4">Public</td>
                    <td className="px-6 py-4">1 Reply</td>
                    <td className="px-6 py-4">Apr 25, 2017 04:31pm</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NetaProfileDetails;
