import React from 'react'
import ModalContainer from './ModalContainer';

type PropsTypes = {
  open: boolean;
  closeModal: () => void;
  video: {
    title: string;
    src: string;
  };
};

const VideoModal = (props: PropsTypes) => {
  return (
    <ModalContainer open={props.open} closeModal={props.closeModal}>
      <div className="pr_overlay fixed left-0 top-0 h-full w-full overflow-x-hidden overflow-y-auto bg-[rgba(0,0,0,0.5)] z-20">
        <div className="modal_dialog relative w-auto transform-none xl:max-w-[1000px] lg:max-w-[950px] md:max-w-[700px] sm:max-w-[500px] xs:max-w-[400px] xxs:max-w-[340px] sm:min-h-[calc(100%_-_3.5rem)] min-h-[calc(100%_-_1rem)] flex items-center my-8 mx-auto">
          <div className="bg-white relative flex flex-col w-full pointer-events-auto bg-clip-padding rounded-lg p-6">
            <span
              className="inline-flex justify-center items-center h-8 w-8 rounded-full bg-black bg-opacity-80 absolute -top-1 -right-0.5 z-50 cursor-pointer"
              id="em_close"
              onClick={props.closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
            <iframe
              x-show="modalOpen"
              className="w-full md:h-[500px] sm:h-[400px] h-[350px]"
              src={`${props.video.src}`}
              title={`${props.video.title}`}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}

export default VideoModal