import { useAppSelector } from "../../../store/hooks"


const CountdownArea = () => {
  const {homePageSummery} = useAppSelector(state => state.common)
  return (
    <section className="countdown_area relative lg:py-20 py-16 bg-countdown_img bg-center bg-cover bg-fixed">
      <div className="md:container container-fluid mx-auto relative z-10">
        <div className="flex flex-wrap justify-center lg:gap-6 gap-3">
          <div className="count_item bg-white rounded-md shadow-theme_shadow p-3 sm:w-[200px] w-[150px] sm:h-[200px] h-[150px] flex items-center justify-center">
            <div className="item_inner text-center">
              <h4 className="text-black xl:text-3xl lg:text-2xl text-xl font-medium">
                {homePageSummery?.total_question}
                <sub>+</sub>
              </h4>
              <p className="lg:text-lg text-base font-normal text-green-500">
                Questions
              </p>
            </div>
          </div>
          <div className="count_item bg-white rounded-md shadow-theme_shadow p-3 sm:w-[200px] w-[150px] sm:h-[200px] h-[150px] flex items-center justify-center">
            <div className="item_inner text-center">
              <h4 className="text-black xl:text-3xl lg:text-2xl text-xl font-medium">
                {homePageSummery?.total_member}
              </h4>
              <p className="lg:text-lg text-base font-normal text-green-500">
                MPs
              </p>
            </div>
          </div>
          <div className="count_item bg-white rounded-md shadow-theme_shadow p-3 sm:w-[200px] w-[150px] sm:h-[200px] h-[150px] flex items-center justify-center">
            <div className="item_inner text-center">
              <h4 className="text-black xl:text-3xl lg:text-2xl text-xl font-medium">
                {homePageSummery?.total_answer}
                <sub>+</sub>
              </h4>
              <p className="lg:text-lg text-base font-normal text-green-500">
                Answers
              </p>
            </div>
          </div>
          <div className="count_item bg-white rounded-md shadow-theme_shadow p-3 sm:w-[200px] w-[150px] sm:h-[200px] h-[150px] flex items-center justify-center">
            <div className="item_inner text-center">
              <h4 className="text-black xl:text-3xl lg:text-2xl text-xl font-medium">
                22193<sub>+</sub>
              </h4>
              <p className="lg:text-lg text-base font-normal text-green-500">
                Volunteers
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40"></div>
    </section>
  );
}

export default CountdownArea