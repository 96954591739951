import React from 'react'
import RecentQuesAns from './RecentAns'
import LatestVideo from './LatestVideo';
import TopQuestion from './TopQuestion';

const RecentArea = () => {
  return (
    <section className="three_section lg:pb-10 pb-8">
      <div className="container-fluid mx-auto">
        <div className="grid xl:grid-cols-3 md:grid-cols-2 md:gap-6 gap-3">
          <TopQuestion />
                  <RecentQuesAns />
                  <LatestVideo />
        </div>
      </div>
    </section>
  );
}

export default RecentArea