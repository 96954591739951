
import { useAppSelector } from '../../../store/hooks';

const ListBanner = () => {
    const { generalSettings } = useAppSelector(
      (state) => state.common
    );
  return (
    <section className="banner_area">
      <div className="container-fluid mx-auto">
        <div className="grid grid-flow-row grid-cols-12 sm:gap-6 gap-3">
          <div className="col-span-12 row-span-2">
            <div className="banner_thumb w-full h-full aspect-w-6 aspect-h-2">
              <img
                src={generalSettings?.banner}
                className="absolute inset-0 rounded-lg"
                alt="banner"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ListBanner