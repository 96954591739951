import { Link } from "react-router-dom";


const PetitionList = () => {
  return (
    <section className="petition_area bg-[#fafbff] lg:py-10 py-8">
      <div className="container mx-auto flex flex-col gap-20">
        <div className="bg-white p-4 rounded-md shadow-theme_shadow max-w-[800px] mx-auto">
          <div className="bg-blue-500 p-3 rounded shadow-theme_shadow">
            <p className="text-white sm:text-base text-sm font-normal text-justify">
              যেকোনো জনগুরুত্বপুর্ণ বিষয়ের পিটিশনে আমারএমপি ডটকম ১ হাজার সাইন
              হওয়ার পর একজন সংসদ সদস্যের মাধ্যমে পিটিশনের বিষয়টি জাতীয় সংসদে
              উপস্থাপনের চেষ্টা করবে। তবে এক হাজার বা ততোধিক সাইনই যেকোনো বিষয়ে
              আলোচনার জন্যে সর্বশেষ সংখ্যা নয়; এটা কর্তৃপক্ষের চাহিদা ও সামগ্রিক
              দিক বিবেচনা করে বাড়তেও পারে। এখানে উল্লেখ থাকে যে, প্রয়োজনীয়
              ডিজিটাল সাইনই যেকোনো বিষয়ে আলোচনার জন্যে সর্বশেষ শর্ত নয়, উপযুক্ত
              কর্তৃপক্ষ এ বিষয়ে সিদ্ধান্ত দেওয়ার অধিকার সংরক্ষণ করেন।
            </p>
          </div>
          <div className="card p-3">
            <div className="card_head py-5">
              <h3 className="inline-block text-green-500 xl:text-2xl md:text-xl text-lg font-medium relative z-[1] p-2 after:absolute after:block after:left-0 after:top-0 after:bg-white after:border after:shadow-theme_shadow after:w-full after:h-full after:skew-x-[20deg] after:-z-10">
                Petition
              </h3>
            </div>
            <div className="card_body bg-white py-8 px-4 rounded-md shadow-theme_shadow">
              <Link to="/petition/details/1">
                <h4 className="text-black md:text-xl text-lg font-medium pb-2 hover:text-green-500 transition-all">
                  ২০১৭ সালের মাধ্যমিক পরীক্ষায় প্রশ্নপত্র ফাঁস সংক্রান্ত
                  অনুসন্ধান
                </h4>
              </Link>
              <div className="flex flex-wrap items-center gap-2">
                <p className="text-green-500 text-base font-normal">
                  Petition Created By:
                  <span className="font-medium">Omar Shehab</span>,
                </p>
                <span className="text-[#4a4a4a] text-opacity-70">
                  Feb 28, 2017 01:55pm
                </span>
              </div>
              <p className="text-green-500 text-sm font-normal">
                Total Signaure: 1555
              </p>
              <p className="text-black text-opacity-70 text-base font-normal">
                ২০১৭ সালের মাধ্যমিক পরীক্ষার প্রশ্নপত্র ফাঁস সংক্রান্ত অনুসন্ধান
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PetitionList