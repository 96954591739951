import React, { useEffect, useRef, useState } from "react";
import SearchNeta from "../../components/Home/SearchNeta";
import NewsList from "../../components/News/NewsList";
import Pagination from "../../components/common/Pagination";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getNewsList } from "../../store/API/NewsApis";
import { useNavigate, useSearchParams } from "react-router-dom";

const News = () => {
  const { newsList } = useAppSelector((state) => state.common);
  const [remountComponent, setRemountComponent] = useState(0);

  const myRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const limit = 4;

  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (searchParams.get("seat")) {
      dispatch(
        getNewsList({
          page: 1,
          page_size: limit,
          seat: searchParams.get("seat"),
        })
      );
    } else if (searchParams.get("upazila")) {
      dispatch(
        getNewsList({
          page: 1,
          page_size: limit,
          upazila: searchParams.get("upazila"),
        })
      );
    } else if (searchParams.get("district")) {
      dispatch(
        getNewsList({
          page: 1,
          page_size: limit,
          district: searchParams.get("district"),
        })
      );
    } else if (searchParams.get("division")) {
      dispatch(
        getNewsList({
          page: 1,
          page_size: limit,
          division: searchParams.get("division"),
        })
      );
    } else {
      dispatch(
        getNewsList({
          page: 1,
          page_size: limit,
        })
      );
    }
  }, [searchParams, dispatch]);
  const handlePageClick = (data: any) => {
    if (searchParams.get("seat")) {
      dispatch(
        getNewsList({
          page: data.selected + 1,
          page_size: limit,
          seat: searchParams.get("seat"),
        })
      );
    } else if (searchParams.get("upazila")) {
      dispatch(
        getNewsList({
          page: data.selected + 1,
          page_size: limit,
          upazila: searchParams.get("upazila"),
        })
      );
    } else if (searchParams.get("district")) {
      dispatch(
        getNewsList({
          page: data.selected + 1,
          page_size: limit,
          district: searchParams.get("district"),
        })
      );
    } else if (searchParams.get("division")) {
      dispatch(
        getNewsList({
          page: data.selected + 1,
          page_size: limit,
          division: searchParams.get("division"),
        })
      );
    } else {
      dispatch(
        getNewsList({
          page: data.selected + 1,
          page_size: limit,
        })
      );
    }
    window.scrollTo({
      top: 180,
      behavior: "smooth",
    });
  };

  const handleSearchSubmit = (data: any) => {
    if (data?.seat) {
      navigate(`/news/list?seat=${data?.seat?.value}`);
    } else if (data?.upazila) {
      navigate(`/news/list?upazila=${data?.upazila?.value}`);
    } else if (data?.district) {
      navigate(`/news/list?district=${data?.district?.value}`);
    } else if (data?.division) {
      navigate(`/news/list?division=${data?.division?.value}`);
    }
    setRemountComponent(Math.random());
  };

  return (
    <>
      <section className="three_section bg-[#fafbff] lg:py-10 py-8">
        <div className="container-fluid mx-auto">
          <h3 className="text-green-500 xl:text-2xl md:text-xl text-lg font-medium">
            latest
            <span className="text-red-500 inline-block relative z-[1] p-2 after:absolute after:block after:left-0 after:top-0 after:bg-white after:border after:shadow-theme_shadow after:w-full after:h-full after:skew-x-[20deg] after:-z-10">
              News
            </span>
            Update
            <span>(Total: {newsList?.count})</span>
          </h3>
          <SearchNeta handleSearchSubmit={handleSearchSubmit} />
          <NewsList ref={myRef} />
          <div className="mt-8 flex justify-end" key={remountComponent}>
            <Pagination
              pageCount={newsList?.count && Math.ceil(newsList?.count / limit)}
              handlePageClick={handlePageClick}
              pageRange={5}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default News;
