import React from 'react'
import  Header from "./Header";
import Footer from './Footer';
import { Outlet, ScrollRestoration } from 'react-router-dom';


// type PropTypes = {
//     children: React.ReactNode
// }

const Layout = () => {
    return (
      <div>
        <Header />
        <ScrollRestoration />
        {/* {children} */}
        <Outlet />
        <Footer />
      </div>
    );
};

export default Layout