import React from 'react'
import { useAppSelector } from '../../../store/hooks';
import { UpazilaType } from '../../../models';

const UpazilaList = () => {
    const {upazila} = useAppSelector(state => state.common)
  return (
    <div className="mt-4 rounded-md shadow-bmpshadow e-upazila">
      {upazila?.length > 0 ? (
        upazila?.map((item: UpazilaType, index: number) => (
          <div className="table-item md:block flex" key={index}>
            <ul
              className={`md:w-full w-2/5 overview_list md:flex items-center px-4 py-3 bg-gradient-to-tl from-[#028FF0]/10 to-[#0DC1D9]/10 bg-opacity-80 rounded-tl-md rounded-tr-md ${
                index > 0 ? "md:hidden" : ""
              }`}
            >
              <li>
                <span className="text-black lg:text-base text-sm font-light text-opacity-70">
                  Serial No.
                </span>
              </li>
              <li>
                <span className="text-black lg:text-base text-sm font-light text-opacity-70">
                  Division
                </span>
              </li>
              <li>
                <span className="text-black lg:text-base text-sm font-light text-opacity-70">
                  District
                </span>
              </li>
              <li>
                <span className="text-black lg:text-base text-sm font-light text-opacity-70">
                  Name
                </span>
              </li>
            </ul>
            <ul className="overview_list md:w-full w-3/5 md:flex items-center px-4 py-3 md:border-b border-[#9D9D9D]">
              <li>
                <span className="lg:text-base text-sm text-black font-normal text-opacity-80">
                  {index + 1}
                </span>
              </li>
              <li>
                <span className="lg:text-base text-sm text-black font-normal text-opacity-80 max-w-[180px] truncate inline-block">
                  {item?.division?.name}
                </span>
              </li>
              <li>
                <span className="lg:text-base text-sm text-black font-normal text-opacity-80 max-w-[180px] truncate inline-block">
                  {item?.district?.name}
                </span>
              </li>
              <li>
                <span className="lg:text-base text-sm text-black font-normal text-opacity-80 max-w-[180px] truncate inline-block">
                  {item?.name}
                </span>
              </li>
            </ul>
          </div>
        ))
      ) : (
        <div className="min-h-[500px] flex items-center justify-center">
          <div className="empty_text text-center">
            {/* <img className={EmptyImg} alt="" /> */}
            <p className="text-[#8d8d8d] lg:text-xl md:text-lg text-base font-normal font-archivo mt-4">
              Empty Division
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default UpazilaList