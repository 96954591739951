import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import parse from "html-react-parser";
import ReactQuill from "react-quill";
import { AnswerType } from "../../../models";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { postAnswer } from "../../../store/API/QuestionAnswerApis";
import { toast } from "react-toastify";

const myColors = [
  "purple",
  "#785412",
  "#452632",
  "#856325",
  "#963254",
  "#254563",
  "white",
];

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ align: ["right", "center", "justify"] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: myColors }],
    [{ background: myColors }],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "color",
  "image",
  "background",
  "align",
];

const SingleQuestionAns: React.FC = () => {
  const { questionAnsDetails } = useAppSelector((state) => state.quesAns);
  const { user } = useAppSelector((state) => state.auth);
  const [answer, setAnswer] = useState('')
  let { id } = useParams();
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const handleAnswerSubmit = () => {
    const data = {
      question: questionAnsDetails?.id,
      member: id,
      content: answer,
      published_date: moment().format("MMMM Do YYYY, h:mm:ss a"),
      router: navigate,
    };
    console.log(data);
    dispatch(postAnswer(data));
    setAnswer("")
    toast.success('Answer submitted successfully')
  }
  return (
    <>
      <div className="qa_wrapper">
        <div className="flex items-center justify-between gap-3">
          <h4 className="text-green-500 text-lg font-medium relative pl-7 after:absolute after:block after:left-0 after:top-1 after:w-4 after:h-4 after:bg-red-500 after:rounded-sm">
            View Questions
          </h4>
          <span className="text-green-500 text-base font-normal">
            {questionAnsDetails?.view} Views
          </span>
        </div>
        <div className="subject py-4">
          <h4 className="flex items-center gap-3 text-black text-opacity-70 lg:text-2xl md:text-xl text-lg font-semibold">
            <span>Subject : {questionAnsDetails?.subject}</span>
          </h4>
        </div>
        <div className="qa_auth flex items-center gap-3">
          <img
            className="h-20 w-20 rounded-full"
            src={questionAnsDetails?.sender?.pro_pic}
            alt=""
          />
          <div className="text_auth">
            <span className="flex items-center gap-2 text-black text-opacity-70 text-base font-normal">
              Written By
              <span className="text-green-500">
                {questionAnsDetails?.sender?.first_name}{" "}
                {questionAnsDetails?.sender?.last_name}
              </span>
            </span>
            <small className="text-green-500 text-sm">
              {questionAnsDetails?.published_date}
            </small>
          </div>
        </div>
        <div className="flex flex-col gap-3 pt-4 text-black text-opacity-70 md:text-base text-sm font-normal">
          {questionAnsDetails?.content && parse(questionAnsDetails?.content)}
        </div>
      </div>
      {user?.id && (
        <div>
          <div className="w-full px-3 mb-2 mt-1">
            <ReactQuill
              // {...register("allergen_info", {
              //   required: "This field is required",
              //   message: "This field is required",
              // })}
              className="mt-1 h-32 md:h-28 mb-2"
              value={answer}
              onChange={setAnswer}
              modules={modules}
              formats={formats}
              preserveWhitespace
            />
          </div>
        </div>
      )}
      {user?.id && (
        <div className="w-full flex justify-end px-3 my-3 mt-16 sm:mt-10">
          <input
            type="submit"
            className="px-2.5 py-1.5 rounded-md text-white text-sm bg-indigo-500 cursor-pointer"
            value="Post Comment"
            onClick={handleAnswerSubmit}
          />
        </div>
      )}

      <div className="anw_wrapper sm:border-t-0 border-t sm:pt-0 pt-8">
        {questionAnsDetails?.answer
          ?.slice()
          ?.reverse()
          ?.map((item: AnswerType) => (
            <div
              key={item?.id}
              className="flex sm:flex-row flex-col items-start gap-5 mb-3"
            >
              <div className="sm:w-[calc(100%_-_120px)] w-full bg-pink-100 rounded-md p-4 sm:order-1 order-2">
                <div className="flex items-center gap-2 text-black text-opacity-70 text-base font-normal">
                  <span>Written By: </span>
                  <span className="text-green-500">
                    {item?.answer_by?.first_name} {item?.answer_by?.last_name}
                  </span>
                </div>
                <div className="flex items-center gap-2 py-3">
                  <span className="text-red-500 text-base font-medium">
                    {item?.published_date}
                  </span>
                  <span className="bg-green-500 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded">
                    Public
                  </span>
                </div>
                <div className="pt-6 text-black text-opacity-70 md:text-base text-sm font-normal flex flex-col gap-3">
                  {item?.content && parse(item?.content)}
                </div>
              </div>
              <div className="ans_thumb w-[100px] sm:order-2 order-1">
                <img
                  className="sm:rounded-full rounded-xl border-2 border-green-500"
                  src={item?.answer_by?.pro_pic}
                  alt=""
                />
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default SingleQuestionAns;
