import { useAppSelector } from "../../../store/hooks";

const NetaListBanner = () => {
  const {memberDetails} = useAppSelector(state => state.member)
  return (
    <section className="banner_area">
      <div className="container-fluid mx-auto">
        <div className="grid grid-flow-row grid-cols-12 sm:gap-6 gap-3">
          <div className="col-span-12 row-span-2">
            <div className="banner_thumb w-full h-full aspect-w-6 aspect-h-2">
              {memberDetails?.cover_pic && (
                <img
                  src={memberDetails?.cover_pic}
                  className="absolute inset-0 rounded-lg"
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NetaListBanner