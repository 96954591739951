import React from 'react'
import AuthLayoutHeader from './AuthLayoutHeader';
import Footer from '../Layout/Footer';
import { Outlet, ScrollRestoration } from 'react-router-dom';

const AuthLayout = () => {
  return (
    <div>
      <AuthLayoutHeader />
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
}

export default AuthLayout