import { createSlice } from "@reduxjs/toolkit";
import { AmbassadorListType, UserDetailsType, UserType } from "../models";
import {
  followNeta,
  getAdminAmbassadorList,
  getAmbassadorList,
  getUser,
  getUserProfile,
  login,
  registration,
} from "./API/UserApis";
import auth from "../utils/auth";

const initialState: {
  loading: boolean;
  user: UserType;
  userDetails: UserDetailsType | null;
  ambassadorList: AmbassadorListType | null;
  adminAmbassadorList: AmbassadorListType | null;
  followMemberMgs: string | null;
  error: any;
} = {
  loading: false,
  user: {},
  userDetails: null,
  ambassadorList: null,
  adminAmbassadorList: null,
  followMemberMgs: null,
  error: null
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(getAmbassadorList.fulfilled, (state, action) => {
      state.loading = false;
      state.ambassadorList = action.payload;
    });
    builder.addCase(getAdminAmbassadorList.fulfilled, (state, action) => {
      state.loading = false;
      state.adminAmbassadorList = action.payload;
    });
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.userDetails = action.payload;
    });
    builder.addCase(followNeta.fulfilled, (state, action) => {
      state.loading = false;
      state.followMemberMgs = action.payload;
    });
    builder.addCase(registration.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(registration.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(registration.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(login.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
  reducers: {
    logout: (state) => {
      auth.clearToken("jwtToken");
      state.user = {};
    },
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
