import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../settings/config";
import useAxios from "../../utils/useAxios";


export const getGeneralSettings = createAsyncThunk(
  "settings/general",
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/v1/meta/general`
      );
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getVideos = createAsyncThunk(
  "settings/videos",
  async (paramsData: { category: string, limit: number }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/v1/meta/videos`,
        {
          params: paramsData,
        }
      );
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getLatestVideos = createAsyncThunk(
  "settings/latestVideos",
  async (
    paramsData: { category: string},
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.get(`${API_URL}/v1/meta/videos`, {
        params: paramsData,
      });
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getHomePageSummery = createAsyncThunk(
  "settings/HomePageSummery",
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API_URL}/v1/meta/home-page-summery`);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getPartyList = createAsyncThunk(
  "common/party-list",
  async (paramsData, { rejectWithValue }) => {
    try {
      let api = useAxios();
      const { data } = await api.get(`${API_URL}/v1/info/party-list`);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
