import React from "react";
import { AiOutlineCheckCircle, AiOutlineEdit } from "react-icons/ai";
import { RiCloseCircleLine, RiDeleteBin6Line } from "react-icons/ri";

// Define the structure of a column
type Column = {
  header: string;
  accessor: any | ((row: any) => any);
};

// Define the props for the Table component
type TableProps<T> = {
  columns: Column[];
  data: T[];
  onEdit?: (data: T) => void;
  onDelete?: (id: number) => void;
  extraAction?: (data: T) => void;
};

// Table component
function Table<T>({ columns, data, onEdit, onDelete, extraAction }: TableProps<T>) {
  console.log(data);
  
  return (
    <div className="mt-4 rounded-md shadow-bmpshadow e-district">
      {data.length > 0 ? (
        data.map((item: any, index) => (
          <div className="table-item md:block flex" key={index}>
            <ul
              className={`md:w-full w-2/5 overview_list md:flex items-center px-4 py-3 bg-gradient-to-tl from-[#028FF0]/10 to-[#0DC1D9]/10 bg-opacity-80 rounded-tl-md rounded-tr-md ${
                index > 0 ? "md:hidden" : ""
              }`}
            >
              {columns.map((column, columnIndex) => (
                <li key={columnIndex}>
                  <span className="text-black lg:text-base text-sm font-light text-opacity-70">
                    {column.header}
                  </span>
                </li>
              ))}
              <li>
                <span className="text-black lg:text-base text-sm font-light text-opacity-70">
                  Action
                </span>
              </li>
            </ul>
            <ul className="overview_list md:w-full w-3/5 md:flex items-center px-4 py-3 md:border-b border-[#9D9D9D]">
              {columns.map((column, columnIndex) => (
                <li key={columnIndex}>
                  <span className="lg:text-base text-sm text-black font-normal text-opacity-80 max-w-[180px] truncate inline-block">
                    {typeof column.accessor === "function"
                      ? (column.accessor(item) as React.ReactNode)
                      : (item as any)[column.accessor]}
                  </span>
                </li>
              ))}
              <li>
                <span className="lg:text-base text-sm text-black font-normal text-opacity-80">
                  {onEdit && (
                    <button onClick={() => onEdit(item)}>
                      <AiOutlineEdit className="mr-2 text-yellow-500" />
                    </button>
                  )}
                  {onDelete && (
                    <button onClick={() => onDelete((item as any).id)}>
                      <RiDeleteBin6Line className="mx-2 text-red-500" />
                    </button>
                  )}
                  {extraAction && (
                    <button onClick={() => extraAction(item)}>
                      {item.status ? (
                        <RiCloseCircleLine className="mx-2 text-gray-500" />
                      ) : (
                        <AiOutlineCheckCircle className="mx-2 text-green-500" />
                      )}
                    </button>
                  )}
                </span>
              </li>
            </ul>
          </div>
        ))
      ) : (
        <p className="text-center text-gray-500">No data available</p>
      )}
    </div>
  );
}

export default Table;
