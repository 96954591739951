import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { memberQuestion } from "../../../store/API/QuestionAnswerApis";
import { getMemberNews } from "../../../store/API/NewsApis";
import { AmbassadorShortDetailsType } from "../../../models";

type PropsType = {
  toggleTab: number;
  memberId?: string;
  setToggleTab: React.Dispatch<React.SetStateAction<number>>;
  setOpenAmbassadorModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const NetaProfileDetailsSidebar = (props: PropsType) => {
  const { memberDetails } = useAppSelector((state) => state.member);
  const { user } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useAppDispatch();
  console.log(memberDetails);
  
  return (
    <div className="lg:col-span-3 lg:block hidden">
      <div className="bg-green-500 p-4 rounded-md">
        <ul className="flex flex-col gap-5 text-white lg:text-lg text-base font-medium">
          <li onClick={() => props.setToggleTab(1)}>
            <p
              className={`hover:text-black hover:ml-1 transition-all cursor-pointer ${
                props.toggleTab === 1 ? "text-black" : "text-white"
              }`}
            >
              Biography
            </p>
          </li>
          <li onClick={() => props.setToggleTab(2)}>
            <p
              className={`hover:text-black hover:ml-1 transition-all cursor-pointer ${
                props.toggleTab === 2 ? "text-black" : "text-white"
              }`}
            >
              Election History
            </p>
          </li>
          <li
            onClick={() => {
              props.setToggleTab(3);
              if (props.memberId) dispatch(memberQuestion(props?.memberId));
            }}
          >
            <p
              className={`hover:text-black hover:ml-1 transition-all cursor-pointer ${
                props.toggleTab === 3 ? "text-black" : "text-white"
              }`}
            >
              {location?.state?.paramsType
                ? location?.state?.paramsType
                : "Question"}
            </p>
          </li>
          <li
            onClick={() => {
              props.setToggleTab(4);
              if (props.memberId) dispatch(getMemberNews(props?.memberId));
            }}
          >
            <p
              className={`hover:text-black hover:ml-1 transition-all cursor-pointer ${
                props.toggleTab === 4 ? "text-black" : "text-white"
              }`}
            >
              News
            </p>
          </li>
          <li onClick={() => props.setToggleTab(5)}>
            <p
              className={`hover:text-black hover:ml-1 transition-all cursor-pointer ${
                props.toggleTab === 5 ? "text-black" : "text-white"
              }`}
            >
              Wealth
            </p>
          </li>
          <li onClick={() => props.setToggleTab(6)}>
            <p
              className={`hover:text-black hover:ml-1 transition-all cursor-pointer ${
                props.toggleTab === 6 ? "text-black" : "text-white"
              }`}
            >
              Wealth 2014
            </p>
          </li>
          <li onClick={() => props.setToggleTab(7)}>
            <p
              className={`hover:text-black hover:ml-1 transition-all cursor-pointer ${
                props.toggleTab === 7 ? "text-black" : "text-white"
              }`}
            >
              Manifesto
            </p>
          </li>
        </ul>
      </div>
      <div className="bg-gray-200 rounded-md p-4 text-center mt-6">
        <h4 className="text-black text-xl font-medium pb-4">Ambassador</h4>
        {memberDetails?.ambassadors &&
        memberDetails?.ambassadors?.length > 0 ? (
          memberDetails?.ambassadors?.map(
            (item: AmbassadorShortDetailsType) => (
              <>
                <div className="profile_thumb w-[150px] text-center rounded-md mx-auto">
                  {item?.pro_pic && (
                    <img
                      className="w-full rounded-md"
                      src={item?.pro_pic}
                      alt=""
                    />
                  )}
                </div>
                <div>{item.first_name}</div>
              </>
            )
          )
        ) : (
          <p className="text-red-300 text-base font-normal">
            No ambassador found for this MP
          </p>
        )}

        {memberDetails?.ambassadors &&
          memberDetails?.total_ambassadors_to_apply &&
          memberDetails?.total_ambassadors_to_apply >
          memberDetails?.ambassadors?.length
          &&
          user?.id
          &&
          !memberDetails?.ambassadors?.some(
            (ambassador:any) => ambassador.id === user?.id
          )
          && (
            <button
              onClick={() => props.setOpenAmbassadorModal(true)}
              className="bg-red-400 p-2 sm:w-[200px] xs:w-[160px] text-center rounded-md flex items-center gap-2 text-white hover:bg-red-600 mx-auto"
            >
              Apply to represent him
            </button>
          )}
      </div>
    </div>
  );
};

export default NetaProfileDetailsSidebar;
