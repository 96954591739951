import {
  createSlice,
} from "@reduxjs/toolkit";
import { getDistrictList, getDivisionList, getSeatList, getUpazilaList } from "./apis";
import {
  DistrictType,
  // DistrictTypeB,
  DivisionType,
  GeneralSettingsType,
  HomePageSummerType,
  NewsType,
  PartyType,
  SeatType,
  TopNewsType,
  UpazilaType,
  VideosType,
} from "../models";
import { getGeneralSettings, getHomePageSummery, getLatestVideos, getPartyList, getVideos } from "./API/CommonApis";
import { getBatchNews, getGeneralNews, getMemberNews, getNewsDetails, getNewsList, getNewsSuggestionByDivision, getTopNews } from "./API/NewsApis";

const initialState: {
  division: DivisionType[];
  district: DistrictType[];
  // district: DistrictTypeB;
  upazila: UpazilaType[];
  seat: SeatType[];
  topVideos: VideosType[];
  latestVideos: VideosType[];
  generalSettings: GeneralSettingsType | null;
  topNews: TopNewsType[];
  newsList: NewsType | null;
  generalNews: TopNewsType[];
  memberNews: TopNewsType[];
  newsDetails: TopNewsType | null;
  batchNews: TopNewsType[];
  newsSuggestion: TopNewsType[];
  homePageSummery: HomePageSummerType | null;
  partyList: PartyType[];
  loading: boolean;
} = {
  division: [],
  // district: {
  //   count: 0,
  //   results: [],
  // },
  district: [],
  upazila: [],
  seat: [],
  topVideos: [],
  generalNews: [],
  latestVideos: [],
  topNews: [],
  newsList: null,
  memberNews: [],
  batchNews: [],
  newsSuggestion: [],
  newsDetails: null,
  generalSettings: null,
  homePageSummery: null,
  partyList: [],
  loading: false,
};

const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getDivisionList.fulfilled, (state, action) => {
      state.loading = false
      state.division = action.payload
    });
    builder.addCase(getDistrictList.fulfilled, (state, action) => {
      state.loading = false;
      state.district = action.payload;
    });
    builder.addCase(getUpazilaList.fulfilled, (state, action) => {
      state.loading = false;
      state.upazila = action.payload;
    });
    builder.addCase(getSeatList.fulfilled, (state, action) => {
      state.loading = false;
      state.seat = action.payload;
    });
    builder.addCase(getGeneralSettings.fulfilled, (state, action) => {
      state.loading = false;
      state.generalSettings = action.payload;
    });
    builder.addCase(getVideos.fulfilled, (state, action) => {
      state.loading = false;
      state.topVideos = action.payload;
    });
    builder.addCase(getLatestVideos.fulfilled, (state, action) => {
      state.loading = false;
      state.latestVideos = action.payload;
    });
    builder.addCase(getTopNews.fulfilled, (state, action) => {
      state.loading = false;
      state.topNews = action.payload;
    });
    builder.addCase(getGeneralNews.fulfilled, (state, action) => {
      state.loading = false;
      state.generalNews = action.payload;
    });
    builder.addCase(getMemberNews.fulfilled, (state, action) => {
      state.loading = false;
      state.memberNews = action.payload;
    });
    builder.addCase(getNewsDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.newsDetails = action.payload;
    });
    builder.addCase(getBatchNews.fulfilled, (state, action) => {
      state.loading = false;
      state.batchNews = action.payload;
    });
    builder.addCase(getNewsSuggestionByDivision.fulfilled, (state, action) => {
      state.loading = false;
      state.newsSuggestion = action.payload;
    });
    builder.addCase(getHomePageSummery.fulfilled, (state, action) => {
      state.loading = false;
      state.homePageSummery = action.payload;
    });
    builder.addCase(getNewsList.fulfilled, (state, action) => {
      state.loading = false;
      state.newsList = action.payload;
    });
    builder.addCase(getPartyList.fulfilled, (state, action) => {
      state.loading = false;
      state.partyList = action.payload;
    });
  },
  reducers: {},
});

export const commonActions = commonSlice.actions;
export default commonSlice.reducer;