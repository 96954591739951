import React from "react";
import ModalContainer from "../../common/ModalContainer";
import ReactQuill from "react-quill";

const myColors = [
  "purple",
  "#785412",
  "#452632",
  "#856325",
  "#963254",
  "#254563",
  "white",
];

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ align: ["right", "center", "justify"] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: myColors }],
    [{ background: myColors }],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "color",
  "image",
  "background",
  "align",
];

type PropsTypes = {
  open: boolean;
  closeModal: () => void;
  handleSubmitQuestion: () => void;
  setSubject: React.Dispatch<React.SetStateAction<string>>;
  subject: string;
  content: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
};

const AskQuestionModel: React.FC<PropsTypes> = ({
  closeModal,
  open,
  subject,
  setSubject,
  content,
    setContent,
  handleSubmitQuestion
}) => {

  return (
    <ModalContainer open={open} closeModal={closeModal}>
      <div className="pr_overlay fixed left-0 top-0 h-full w-full overflow-x-hidden overflow-y-auto bg-[rgba(0,0,0,0.5)] z-20">
        <div className="modal_dialog relative w-auto transform-none xl:max-w-[1000px] lg:max-w-[950px] md:max-w-[700px] sm:max-w-[500px] xs:max-w-[400px] xxs:max-w-[340px] sm:min-h-[calc(100%_-_3.5rem)] min-h-[calc(100%_-_1rem)] flex items-center my-8 mx-auto">
          <div className="bg-white relative flex flex-col w-full pointer-events-auto bg-clip-padding rounded-lg p-6">
            <span
              className="inline-flex justify-center items-center h-8 w-8 rounded-full bg-black bg-opacity-80 absolute -top-1 -right-1 z-50 cursor-pointer"
              id="em_close"
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
            <div className="e_modal_body">
              <div className="modal-text text-center pt-4">
                <h1 className="font-bold mb-3">Please Write Question</h1>
                <div className="input_group md:col-span-4 col-span-3 w-full md:w-2/3 mx-auto">
                  <input
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                    type="text"
                    className="border-[.5px] border-black 2xl:h-[45px] h-10 p-2 rounded-md w-full focus:ring-transparent focus:border-primary_color"
                    placeholder="Subject of the question"
                  />
                </div>
                <div className="input_group md:col-span-4 col-span-3 w-full md:w-2/3 mx-auto">
                  <ReactQuill
                    className="mt-1 h-32 md:h-28 mb-2"
                    value={content}
                    onChange={setContent}
                    modules={modules}
                    formats={formats}
                    preserveWhitespace
                    placeholder="Write the content of the question"
                  />
                </div>
              </div>
              <div className="input_btn text-center sm:w-[112px] mx-auto mt-24 md:mt-20 w-full">
                <button
                  onClick={handleSubmitQuestion}
                  type="button"
                  id="mail_change_btn"
                  className="bg-green-500 text-black 2xl:text-lg text-base 2xl:h-[45px] h-10 px-2 w-full rounded-md hover:bg-theme_color transition-all"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default AskQuestionModel;
