import React from 'react'
import PetitionDetailsContentSummery from './PetitionDetailsContentSummery'
import PetitionDetailsSignup from './PetitionDetailsSignup'

const PetitionDetailsContent = () => {
  return (
      <div className="grid grid-cols-12 gap-4">
          <PetitionDetailsContentSummery />
          <PetitionDetailsSignup/>
          </div>
  )
}

export default PetitionDetailsContent