let API_URL = "";

if (process.env.NODE_ENV === "production") {
  API_URL = `${process.env.REACT_APP_PRO_URL}/api`;
} else {
  API_URL = `${process.env.REACT_APP_DEV_URL}/api`;
}

export { API_URL };

let IMAGE_URL = "";

if (process.env.NODE_ENV === "production") {
  IMAGE_URL = `${process.env.REACT_APP_PRO_URL}`;
} else {
  IMAGE_URL = `${process.env.REACT_APP_DEV_URL}`;
}

export { IMAGE_URL };
