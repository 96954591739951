import React, { useState } from "react";
import VideoModal from "../../common/VideoModal";
import { useAppSelector } from "../../../store/hooks";
import { VideosType } from "../../../models";

const LatestVideo = () => {
  const { latestVideos } = useAppSelector(
    (state) => state.common
  );
  const [video, setVideo] = useState<{
    title: string;
    src: string;
  }>({
    title: "",
    src: "",
  });
  const [open, setIsOpen] = useState<boolean>(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div className="top_qa_update xl:col-span-1 md:col-span-2">
      <div className="bg-white p-5 text-center shadow-theme_shadow">
        <h3 className="text-green-500 xl:text-2xl md:text-xl text-lg font-medium pb-8">
          Latest
          <span className="text-red-500 inline-block relative z-[1] p-2 after:absolute after:block after:left-0 after:top-0 after:bg-white after:border after:shadow-theme_shadow after:w-full after:h-full after:skew-x-[20deg] after:-z-10">
            Videos
          </span>
          From Youtube
        </h3>
        <div className="grid sm:grid-cols-2 gap-3 max-h-[600px] overflow-y-auto">
          {latestVideos.map((i: VideosType) => (
            <div className="video_item" key={i.id}>
              <div className="flex justify-center">
                <div className="[&_[x-cloak]]:hidden">
                  <button
                    className="relative flex justify-center items-center focus:outline-none focus-visible:ring rounded-3xl group"
                    aria-controls="modal"
                    aria-label="Watch the video"
                    onClick={() => {
                      setVideo({
                        src: i.link,
                        title:
                          "বাংলার যে উন্নত শহর ইবনে বতুতাকে মুগ্ধ করেছিল | Ibn Battuta in Bengal | Labid Rahat",
                      });
                      setIsOpen(true);
                    }}
                  >
                    <img
                      className="rounded-md shadow-theme_shadow transition-shadow duration-300 ease-in-out"
                      src={`https://i4.ytimg.com/vi/${i.video_id}/hqdefault.jpg`}
                      alt="Modal video thumbnail"
                    />
                    <svg
                      className="absolute pointer-events-none group-hover:scale-110 transition-transform duration-300 ease-in-out"
                      xmlns="http://www.w3.org/2000/svg"
                      width="72"
                      height="72"
                    >
                      <circle
                        className="fill-white"
                        cx="36"
                        cy="36"
                        r="36"
                        fillOpacity=".8"
                      />
                      <path
                        className="fill-red-500 drop-shadow-2xl"
                        d="M44 36a.999.999 0 0 0-.427-.82l-10-7A1 1 0 0 0 32 29V43a.999.999 0 0 0 1.573.82l10-7A.995.995 0 0 0 44 36V36c0 .001 0 .001 0 0Z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <VideoModal open={open} closeModal={closeModal} video={video} />
    </div>
  );
};

export default LatestVideo;
