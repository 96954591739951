
import PetitionBanner from '../../components/Petition/PetitionBanner'
import PetitionList from '../../components/Petition/PetitionList'

const Petition = () => {
  return (
      <div className='text-left'>
          <PetitionBanner />
          <PetitionList/>
    </div>
  )
}

export default Petition