import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import DivisionCreate from "../../../components/Admin/DIvision/DivisionCreate";
import DivisionListContent from "../../../components/Admin/DIvision/DivisionListContent";
import { deleteDivision, editDivision, getDivisionList, postDivision } from "../../../store/apis";

const DivisionList = () => {
    const [divisionData, setDivisionData] = useState<string>("")
  const [tempData, setTempData] = useState<{ state: string, data: number }>({
    state: '',
    data: 0
    })
  const dispatch = useAppDispatch();
  
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        dispatch(postDivision({ name: divisionData }));
        setDivisionData("")
  };
  const handleEditClick = (data: {id: number, name: string}) => {
    setTempData({
      state: "edit",
      data: data.id
    })
    setDivisionData(data.name)
  }
  const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (tempData.state === "edit") {
      dispatch(editDivision({ name: divisionData, id: tempData.data }));
      setTempData({
        state: "",
        data: 0,
      });
      setDivisionData("");
    }
  };

  const handleDeleteClick = (data: { id: number }) => {
    dispatch(deleteDivision({ id: data.id }))
  }

  useEffect(() => {
    dispatch(getDivisionList());
  }, [dispatch]);
  return (
    <div className="flex flex-col gap-4 px-4 pt-6 text-start justify-center">
      <DivisionCreate
        handleSubmit={handleSubmit}
        divisionData={divisionData}
        setDivisionData={setDivisionData}
        handleEditSubmit={handleEditSubmit}
        tempData={tempData}
      />
      <DivisionListContent
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
      />
    </div>
  );
};

export default DivisionList;
