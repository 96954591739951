import React, { useState } from "react";
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";

const AdminLayout = () => {
  const [openSidebar, setOpenSidebar] = useState<boolean>(false)
  return (
    <div className="dark:bg-gray-800">
      <div x-data="{ isMobileMainMenuOpen : true }" className="main_sec">
        <AdminHeader
          setOpenSidebar={setOpenSidebar}
          openSidebar={openSidebar}
        />
        <div className="flex pt-16 overflow-hidden bg-gray-50 dark:bg-gray-900">
          <Sidebar openSidebar={openSidebar} />
          <div className="relative w-full h-full overflow-y-auto bg-gray-50 lg:pl-64 dark:bg-gray-900">
            <Outlet />
            <AdminFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
