import './App.css';
import { RouteApp } from './routes';
import { RouterProvider } from "react-router-dom";


function App() {
  return (
    <div className="App">
      <RouterProvider router={RouteApp} />
    </div>
  );
}

export default App;
