import { configureStore } from "@reduxjs/toolkit";
import commonReducer from "./common.store";
import authReducer from "./auth.store";
import memberReducer from "./member.store";
import quesAnsReducer from "./quesionAnswer.store";

export const store = configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    member: memberReducer,
    quesAns: quesAnsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AddDispatch = typeof store.dispatch;
export default store;
